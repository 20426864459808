@import "../../assets/variables";
@import "../../assets/mixins";

.instructorVerificationCode {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    max-width: 500px;
    padding: 1rem;

    &__content {
      margin-bottom: 1rem;
    }

    h1 {
      font-size: $font-size-xl;
    }

    &__links {
      display: inline-block;
      width: 100%;
      &__faq {
        margin-top: 0.3rem;
        text-align: center;
        a {
          font-size: 0.75rem;
          color: #3957a8;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          text-decoration: none;
        }
        a:hover {
          color: #092981;
        }
      }
    }
  }
}
