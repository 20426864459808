@import "../../assets/variables";
@import "../../assets/mixins";

@mixin teachingSubjectSelectStyles {
  .teachingSubjectSelect__reactSelect {
    &__control {
      font-size: $font-size-s;
      background-color: $casio-light-grey;
      border-radius: $border-radius-base;
      font-weight: 500;
      line-height: 1.5;
      border: none;
      padding: ($font-size-s + 0.4rem) 0.5rem 0.5rem 0.5rem;
      @include transitions();

      &--is-focused {
        background-color: $casio-blue-5;
      }
      &--is-focused .teachingLevel__reactSelect__single-value {
        color: $casio-blue-2;
      }
      &--is-focused .teachingLevel__reactSelect__placeholder {
        color: $casio-blue-3;
      }
      &--is-disabled {
        z-index: 1;
        opacity: 0.5;
      }
      &:hover {
        background-color: $casio-blue-5;
      }
    }

    &__value-container {
      padding: 0;
    }

    &__indicator-separator {
      margin: 0 0.25rem;
    }

    &__indicator {
      padding: 0;
    }

    &__placeholder {
      font-size: $font-size-s;
    }

    &__input {
      font-weight: 800;
    }

    &__menu {
      z-index: 2;
      font-size: $font-size-s;
    }
  }

  .teachingSubjectSelect__reactSelect__custom {
    &__control {
      font-size: $font-size-s;
      background-color: $casio-light-grey;
      border-radius: $border-radius-base;
      font-weight: 500;
      line-height: 1.5;
      border: none;
      padding: ($font-size-s + 0.4rem) 0.5rem 0.5rem 0.5rem;
      @include transitions();

      &--is-focused {
        background-color: $casio-blue-5;
      }
      &--is-focused .teachingSubjectSelect__reactSelect__single-value {
        color: $casio-blue-2;
      }
      &--is-focused .teachingSubjectSelect__reactSelect__placeholder {
        color: $casio-blue-3;
      }
      &--is-disabled {
        z-index: 1;
        opacity: 0.5;
      }
      &:hover {
        background-color: $casio-blue-5;
      }
    }

    &__value-container {
      padding: 0;
    }

    &__indicator-separator {
      margin: 0 0.25rem;
    }

    &__indicator {
      padding: 0;
    }

    &__placeholder {
      font-size: $font-size-s;
    }

    &__input {
      font-weight: 800;
    }

    &__menu {
      z-index: 2;
      font-size: $font-size-s;
    }

    &__multi-value {
      margin-top: 10px;
      background-color: $casio-blue-2;
      color: $casio-white;
      display: flex;
      justify-content: space-between;

      &__label {
        padding: 5px;
        color: $casio-white;
      }

      &__remove {
        @include transitions();
      }
    }
  }
}

.teachingSubjectSelect {
  position: relative;

  & mark {
    font-weight: 700;
    color: $casio-blue-1;
    background-color: transparent;
  }

  &__reactSelect {
    width: 100%;
    &__option {
      &__header {
        display: block;
      }
      &__info {
        display: block;
        font-size: $font-size-xs;
        color: $casio-dark-grey;
      }
    }
  }

  &__reactSelect__custom {
    width: 100%;
    &__option {
      &__header {
        display: block;
      }
      &__info {
        display: block;
        font-size: $font-size-xs;
        color: $casio-dark-grey;
      }
    }
  }

  label {
    cursor: text;
    display: block;
    font-size: $font-size-xs;
    font-weight: 500;
    color: $casio-dark-grey;
    width: 100%;
    padding: 0.6rem 0.5rem 0.6rem 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    @include transitions();
  }

  :global {
    @include teachingSubjectSelectStyles();
  }
}
