@import "../../assets/variables";
@import "../../assets/mixins";

.container {
  margin-top: 50px;
  .userManagementContainer {
    .searchTypeTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 13.5px;
      text-align: left;
      padding-bottom: 40px;
    }

    .filterContainer {
      .filterContainerRow {
        margin-top: 20px;
      }
      .filterText {
        margin: auto;
      }

      .labelText {
        font-family: Noto Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
      }
    }
    .btnGroup {
      .groupBtn1 {
        padding-top: 50px;

        > Button {
          margin-right: 20px;
        }
      }

      .groupBtn2 {
        padding-top: 50px;
        display: flex;
        justify-content: flex-end;
        > Button {
          margin-left: 20px;
        }
      }
    }
    .tableContainer {
      margin-top: 50px;
    }
  }
}
