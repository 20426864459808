@import "../../assets/variables";
@import "../../assets/mixins";

.progressIndicator {
  padding: 2px;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.progressIndicator__step {
  flex-grow: 1;
  display: flex;
  align-items: center;

  &__title {
    display: none;
  }

  &__indicator {
    border: 2px solid transparent;
    flex-shrink: 0;
    width: $radio-size;
    height: $radio-size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-base;
    font-weight: 600;
    line-height: $radio-size;
    @include transitions();
  }

  &__line {
    flex-grow: 1;
    height: 2px;
    display: block;
    background: $casio-light-grey;
    overflow: hidden;
    margin: 0 0.5rem;
    @include transitions();

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: $casio-blue-2;
      transform: translateX(-101%);
      @include transitions();
    }
  }
}

.progressIndicator__step:last-child {
  flex-grow: 0;
  flex-shrink: 0;

  .progressIndicator__step__indicator {
    margin-right: 0;
  }
}

.progressIndicator__step.active {
  .progressIndicator__step__indicator {
    border-color: $casio-blue-2;
    background-color: $casio-blue-5;
    color: $casio-blue-2;
    transform: scale(1.1);
  }

  .progressIndicator__step__title {
    color: $casio-blue-2;
  }
}

.progressIndicator__step.pending {
  .progressIndicator__step__indicator {
    border-color: $casio-light-grey;
    background-color: $casio-light-grey;
    color: $casio-dark-grey;
  }
  .progressIndicator__step__title {
    color: $casio-dark-grey;
  }
}

.progressIndicator__step.done {
  .progressIndicator__step__indicator {
    border-color: $casio-blue-2;
    background-color: $casio-blue-2;
    color: $casio-white;
  }

  .progressIndicator__step__line {
    background-color: $casio-blue-2;

    &::after {
      transform: translateX(0);
    }
  }
}

@include mq-lg-min {
  .progressIndicator__step__title {
    display: block;
    font-weight: 600;
    font-size: $font-size-base;
    margin-left: 0.5rem;
    line-height: 1.25;
    @include transitions();
  }
}

@include mq-lg-min {
  .progressIndicator {
    margin-bottom: 2rem;
  }
  .progressIndicator_vertical {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;

    .progressIndicator__step {
      padding: 0.75rem 0;
      border-bottom: 1px solid $casio-light-grey;
      width: 100%;

      &__title {
        margin-left: 0.75rem;
      }

      &__indicator {
        margin-left: 0;
      }

      &__line {
        display: none;
      }
    }
  }
}
