@import "../../assets/mixins";
@import "../../assets/variables";

.paymentSelection {
  background: $casio-lighter-grey;
  padding: 1rem;
  border-radius: $border-radius-base;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  h3 {
    font-size: $font-size-base;
    font-weight: 600;
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: column;

    > span {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;
    }
  }

  p {
    font-size: $font-size-s;
    color: $casio-dark-grey;
  }

  &__content {
    margin-top: 0.5rem;
  }
}

.paymentSelectionEntry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $casio-light-grey;
  }

  &__radio {
    flex-grow: 1;
  }
}

.paymentSelectionCollapsable {
  display: block;
  margin: 0.75rem 0;
}

@include mq-md-min {
  .paymentSelection {
    h3 {
      flex-direction: row;
      align-items: center;

      > span {
        margin-bottom: 0;
      }
    }
  }
}
