@import "../../assets/variables";

.joinContext {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ul {
    margin-bottom: 1rem;
    font-size: $font-size-s;
  }
}

.contextTitle {
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.listTitle {
  font-size: $font-size-xs;
  font-weight: 500;
  color: $casio-dark-grey;
}

.description {
  font-size: $font-size-xs;
  color: $casio-dark-grey;
  margin-bottom: 1rem;
}

.box {
  align-self: auto;
  width: 50%;
}
