@import "../../assets/variables";
@import "../../assets/mixins";

.personalInformation {
  background-color: $casio-lighter-grey;
  padding: 1rem;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;

  &__info_block {
    flex: 1 auto;
  }

  &__header {
    font-size: $font-size-xs;
    color: $casio-dark-grey;
  }

  &__text {
    font-size: $font-size-s;
  }
}

.productTable {
  width: 100%;
  margin-top: 1rem;

  & hr {
    border: 0;
    border-top: 1px solid $casio-light-grey;
  }
  & tr > *:first-child {
    text-align: left;
  }
  & tr > *:last-child {
    text-align: right;
  }
  &__product {
    font-size: $font-size-s;
    font-weight: 600;
  }
  &__extra {
    font-size: $font-size-s;
    color: $casio-dark-grey;
  }
  &__sum {
    font-weight: 600;
    color: $casio-blue-2;
  }
  &__warningText {
    font-size: $font-size-s;
    color: $casio-dark-grey;
    line-height: 18px;
    font-weight: 400;
  }
}
