@import "../../assets/variables";
@import "../../assets/mixins";

.recovery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 32px;

  .infoBox {
    width: 60%;
  }

  .infoBox p {
    overflow-wrap: break-word !important;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1.25;
    margin: 0;
    width: 60%;
  }

  &__btn {
    width: 60%;
  }
}
