@import "../../assets/variables";

.lmsNicknameModal {
  &__text {
    p {
      font-size: $font-size-s;
      padding-bottom: 0.5rem;
    }
    padding-bottom: 0.5rem;
  }

  &__privacyPolicy {
    margin-bottom: 0.5rem;
    font-size: $font-size-s;
  }

  &__termsAndConditions {
    margin-bottom: 1.5rem;
  }
}
