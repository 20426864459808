@import "../../../assets/variables";
@import "../../../assets/mixins";

.banner {
  background-color: $classpad-blue-dark;
  color: $casio-white;
  @include box-shadow;
  border-radius: $border-radius-base;

  &__padded {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-size-s;
    flex-direction: column;
    gap: 1rem;

    &__text {
      &__header {
        font-size: $font-size-base;
        font-weight: 500;
        padding-bottom: 0.25rem;
      }
      &__links {
        display: flex;
        font-size: $font-size-s;
        flex-direction: column;
        color: $casio-blue;
        gap: 0.5rem;
        padding-top: 0.5rem;
      }
    }
    &__button {
      min-width: fit-content;
    }
  }
  &.inactive {
    background-color: $classpad-blue-dark;
  }
  &.active {
    background-color: $classpad-green-dark;
    .banner__padded {
      justify-content: left;
    }
    .banner__padded__text__header {
      span {
        background-color: rgba($casio-white, 0.3);
        font-weight: 700;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-width: 0.2rem;
        border-color: rgba($casio-white, 0.5);
        border-style: solid;
      }
    }
  }
  &.expired {
    background-color: $casio-light-grey;
    color: $casio-black;
    .banner__padded {
      justify-content: left;
    }
  }

  i {
    width: 2.25rem;
    min-width: 1.75rem;
    svg {
      width: 100%;
      height: 100%;
      stroke-width: 6%;
    }
  }
}

@include mq-lg-min {
  .banner {
    &__padded {
      flex-direction: row;
    }
  }
}

@include mq-md-min {
  .banner {
    &__padded {
      &__text__links {
        flex-direction: row;
        gap: 2rem;
      }
    }
  }
}
