@import "../../assets/variables";
@import "../../assets/mixins";

.countryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: sans-serif;
  margin-bottom: 24px;
  .countryItem {
    margin-left: 32px;
    display: flex;
  }
  .countryItemLabel {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ .countryItemCheckmark {
      background-color: $casio-light-grey;
    }
    input:checked ~ .countryItemCheckmark:after {
      display: block;
    }
    .countryItemCheckmark {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 20px;
      width: 20px;
      background-color: $casio-light-grey;
      border-radius: 50%;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
      &:after {
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $casio-blue-2;
      }
    }
    &:hover input ~ .countryItemCheckmark {
      background-color: $casio-blue-5;
    }
  }

  h4 {
    margin-bottom: 0;
    margin-right: 32px;
  }
}

.userCheckbox {
  font-family: sans-serif;
  border-left: 3px solid $casio-light-grey;
  padding-left: 20px;
  & > .userChildrenCheckbox {
    margin-bottom: 20px;
  }
  & > .childLabelInputCheckbox {
    .childLabelInputCheckbox {
      margin-bottom: 32px;
      &::before {
        margin-right: 12px;
      }
    }
    label {
      font-weight: 700;
    }
  }
  .userChildrenCheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;

    .labelNonCheckbox {
      width: 100%;
      font-size: medium;
    }

    .labelOfInputCheckbox {
      width: 100%;
      white-space: nowrap;
      font-size: medium;
    }

    .childrenGroup {
      display: flex;
      flex-direction: row;
      width: 100%;
      float: left;

      .twoOrFourItems {
        width: 25%;
        .childrenGroup {
          display: none;
        }
      }

      .threeItems {
        width: 25%;
        .childrenGroup {
          display: none;
        }
      }

      .threeItems:last-child {
        width: 50%;
        .childrenGroup {
          display: none;
        }
      }
    }

    .schoolSelect {
      width: 90%;
    }
  }
}

:root {
  --item-height: 20px;
}

.childLabelInputCheckbox {
  position: relative;
  display: block;
  font-size: medium;
  line-height: 0.9;

  label {
    z-index: 1;
    display: flex;
    align-items: center;
    &::before {
      cursor: pointer;
      content: "";
      flex-shrink: 0;
      display: inline-block;
      width: var(--item-height);
      height: var(--item-height);
      background-color: $casio-light-grey;
      border: 1px solid $casio-blue-4;
      border-radius: $border-radius-base;
      margin-right: 10px;
      @include transitions();
    }
  }
  &__text {
    // margin-top: 4px;
  }
  &__checkmark {
    pointer-events: none;
    position: absolute;
    left: 1px;
    top: 1px;
    width: var(--item-height);
    height: var(--item-height);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: $casio-white;
    transform: scale(0.5);
    @include transitions();
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    width: var(--item-height);
    height: var(--item-height);
    left: 0;
    top: 0;
    opacity: 0;

    &:checked {
      & + label::before {
        background-color: $casio-blue-2;
      }
      & + label > .childLabelInputCheckbox__checkmark {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:hover {
    label::before {
      background-color: $casio-blue-5;
    }
  }

  &:focus-within {
    label::before {
      border-color: $casio-blue-2;
      background-color: $casio-blue-5;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
.fullLabelNonCheckbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  span {
    font-size: 14px;
    color: red;
  }
}
