/*
 * Source: https://www.google.com/get/noto/#sans-lgc
 */

@font-face {
  font-family: "Noto Sans";
  font-weight: 100;
  font-style: normal;
  src: url("./fonts/NotoSans-Thin.ttf");
  src: url("./fonts/NotoSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 100;
  font-style: italic;
  src: url("./fonts/NotoSans-ThinItalic.ttf");
  src: url("./fonts/NotoSans-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 200;
  font-style: normal;
  src: url("./fonts/NotoSans-ExtraLight.ttf");
  src: url("./fonts/NotoSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 200;
  font-style: italic;
  src: url("./fonts/NotoSans-ExtraLightItalic.ttf");
  src: url("./fonts/NotoSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/NotoSans-ExtraLight.ttf");
  src: url("./fonts/NotoSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 300;
  font-style: italic;
  src: url("./fonts/NotoSans-LightItalic.ttf");
  src: url("./fonts/NotoSans-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/NotoSans-Regular.ttf");
  src: url("./fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/NotoSans-Italic.ttf");
  src: url("./fonts/NotoSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/NotoSans-Medium.ttf");
  src: url("./fonts/NotoSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/NotoSans-MediumItalic.ttf");
  src: url("./fonts/NotoSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/NotoSans-SemiBold.ttf");
  src: url("./fonts/NotoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 600;
  font-style: italic;
  src: url("./fonts/NotoSans-SemiBoldItalic.ttf");
  src: url("./fonts/NotoSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/NotoSans-Bold.ttf");
  src: url("./fonts/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/NotoSans-BoldItalic.ttf");
  src: url("./fonts/NotoSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 800;
  font-style: normal;
  src: url("./fonts/NotoSans-ExtraBold.ttf");
  src: url("./fonts/NotoSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 800;
  font-style: italic;
  src: url("./fonts/NotoSans-ExtraBoldItalic.ttf");
  src: url("./fonts/NotoSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 800;
  font-style: normal;
  src: url("./fonts/NotoSans-Black.ttf");
  src: url("./fonts/NotoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 800;
  font-style: italic;
  src: url("./fonts/NotoSans-BlackItalic.ttf");
  src: url("./fonts/NotoSans-BlackItalic.ttf") format("truetype");
}
