@import "../../assets/variables";
@import "../../assets/mixins";

.popoverMenu {
  position: relative;
  display: inline-flex;
  button {
    padding: 0;
    display: inline-flex;
    align-items: center;
    font-size: $font-size-base;
  }

  &__popupContainer {
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 1rem;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.5);
    transform-origin: 0 0;
    @include transitions();
  }

  &__popup {
    border-radius: $border-radius-base;
    background-color: $casio-blue-5;
    padding: 0;

    p {
      font-size: $font-size-s;
      padding: 1rem 1.5rem 1rem 1.5rem;
      color: $casio-dark-grey;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $casio-light-grey;

      span:first-child {
        font-weight: 700;
        font-size: $font-size-base;
      }
    }

    > div {
      padding: 0.5rem 0;
    }
  }
}

.popoverMenu:hover {
  .popoverMenu__popupContainer {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

.popoverMenuItem {
  padding: 0.5rem 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $casio-blue-2;
  white-space: nowrap;
  @include transitions();

  &:hover {
    background-color: $casio-blue-4;
  }

  i {
    display: flex;
    align-items: center;

    svg {
      stroke-width: 3;
      margin-right: 0.5rem;
    }
  }
}
