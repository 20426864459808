.group-btn-flex-start {
  padding-top: 50px;
  display: flex;
  justify-content: flex-start;
}

.group-btn-flex-end {
  padding-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.intro-style {
  font-size: 15px;
  width: 96%;
  padding-bottom: 30px;
}

.btn-group-style {
  width: 96%;

  &__groupBtn1 {
    justify-content: start;
  }

  &__groupBtn2 {
    display: flex;
    justify-content: flex-end;
  }
}

.container {
  &__title {
    font-size: x-large !important;
    font-weight: 700;
    line-height: 33.75px;
  }

  margin-top: 40px;

  &__licenseInfo {
    margin-top: 20px;
    &__item {
      display: flex;
      padding-bottom: 10px;
      line-height: 30px;
      font-weight: 600;
      &__label {
        padding-right: 10px;
        font-size: 16px;
      }

      &__value {
        font-size: 16px;
      }
    }
  }

  &__searchFields {
    margin-top: 20px;
  }

  &__btnGroup {
    &__groupBtn1 {
      @extend .group-btn-flex-start;
    }

    &__groupBtn2 {
      @extend .group-btn-flex-end;
      > Button:first-child {
        margin-right: 20px;
      }
    }

    &__groupBtn3 {
      @extend .group-btn-flex-start;
      > Button {
        margin-right: 20px;
      }
    }
  }

  &__tableContainer {
    margin-top: 20px;

    > p {
      padding-bottom: 10px;
    }

    &__viewButton {
      color: #00a1ff;
      font-size: small;
    }
  }
}

.addLicenseCodeContainer {
  &__addLicenseCodeText {
    font-size: 15px;
    width: 96%;
  }

  &__textEmptyLine {
    font-size: 20px;
    padding-bottom: 15px;
    color: white;
    user-select: none;
  }

  &__body {
    margin-top: 30px;
    width: 96%;
  }

  &__btnGroup {
    margin-top: 30px;
    width: 96%;

    &__groupBtn1 {
      justify-content: start;
    }

    &__groupBtn2 {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.addReplaceALContainer {
  &__intro {
    @extend .intro-style;
  }

  &__availableLicenseNum {
    margin-bottom: 30px;

    &__availableCPANum,
    &__availableCPMNum {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__checkBoxGroup {
    label[for="cpapp_accessingdata"] {
      margin-bottom: 10px;
    }

    label[for="cpmanager_accessingdata"] {
      margin-bottom: 20px;
    }
  }

  &__btnGroup {
    @extend .btn-group-style;
  }
}

.licenseDetailModalContainer {
  width: 96%;
  &__text {
    font-size: 20px;
    padding-bottom: 20px;
  }

  &__textEmptyLine {
    font-size: 20px;
    padding-bottom: 20px;
    color: white;
    user-select: none;
  }

  &__groupBtn {
    display: flex;
    justify-content: flex-end;
  }
}

.classwiz {
  &__editClasswizLicense {
    width: 96%;
    margin: auto;

    &__intro {
      @extend .intro-style;
    }

    &__btnGroup {
      margin: auto;
      @extend .btn-group-style;
      width: 100% !important;

      &__groupBtn3 {
        justify-content: center;
      }
    }
  }

  &__announcementText {
    font-size: 15px;
    width: 96%;
  }
}

.deleteClasswizLicenseConfirmText {
  font-size: 15px;
}
