.container {
  &__intro {
    margin-bottom: 10px;
    font-size: 20px;
  }

  &__comparisonBox {
    // padding-top: 2%;
  }
}
