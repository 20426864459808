@import "../../assets/variables";
@import "../../assets/mixins";

* {
  box-sizing: border-box;
}

.container {
  margin-top: 50px;

  .description {
    display: flex;
    flex-direction: column;

    .exampleFile {
      display: inline-block;
      color: #339af0;
      text-decoration: underline;
    }
  }

  .fileUploader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 400px;
    align-items: center;
    justify-content: center;
    outline: 2px dashed $casio-blue-2;
    border-radius: 2px;
    text-align: center;

    .fileUploaded {
      display: inline-block;
      color: #339af0;
      text-decoration: underline;
    }
  }

  .submitBtn {
    float: right;
  }
}
