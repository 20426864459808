.container {
  .headline {
    margin-bottom: 20px;
  }

  .btn {
    display: flex;
    justify-content: space-between;
  }
}
