.container {
  width: 96%;

  &__confirmModalText {
    font-size: 15px;
  }

  &__confirmModalBtnGroup {
    margin-top: 50px;
    &__groupBtn1 {
      justify-content: flex-start;
    }
    &__groupBtn2 {
      display: flex;
      justify-content: flex-end;
    }
  }
}

h2 {
  font-size: x-large !important;
}
