@import "../../assets/mixins";
@import "../../assets/variables";

.pageHeader {
  display: flex;
  flex-direction: column;
  padding: 0 0 1rem 0;

  &.secondary {
    padding: 0 0 0.5rem 0;
  }

  &__heading {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    margin-bottom: 0.5rem;

    &__colorIndicator {
      width: $font-size-xl;
      height: $font-size-xl;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: $border-radius-base;

      &_cyan {
        background-color: $classpad-cyan;
      }

      &_blue {
        background-color: $classpad-blue;
      }

      &_purple {
        background-color: $classpad-purple;
      }

      &_palered {
        background-color: $classpad-pale-red;
      }

      &_magenta {
        background-color: $classpad-magenta;
      }

      &_orange {
        background-color: $classpad-orange;
      }

      &_yellow {
        background-color: $classpad-yellow;
      }

      &_green {
        background-color: $classpad-green;
      }

      &_brown {
        background-color: $classpad-brown;
      }
    }

    &__colorIcon {
      flex: auto;
      color: $casio-blue-3;
      display: flex;
      justify-content: center;

      svg {
        width: 75%;
      }
    }

    &__icon {
      margin-right: 0.5rem;
      flex-basis: content;
      align-self: center;

      svg {
        width: 2rem;
        height: 2rem;
        stroke-width: 3;
        vertical-align: middle;
      }
    }

    &__icon__secondary {
      margin-right: 0.5rem;
      flex-basis: content;
      align-self: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        stroke-width: 3;
        vertical-align: middle;
      }
    }

    &__title {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 1rem;

      &__withContextCode {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        &__contextCode {
          display: flex;
          gap: 5px;
          align-items: center;
          color: $casio-blue-2;
          cursor: pointer;
        }

        h1 {
          font-size: $font-size-xl;
          line-height: 1;
          margin: 0;
        }

        h2 {
          font-size: $font-size-l;
          line-height: 1;
          margin: 0;
        }
      }

      h1 {
        font-size: $font-size-xl;
        line-height: 1;
        margin: 0;
      }

      h2 {
        font-size: $font-size-l;
        line-height: 1;
        margin: 0;
      }
    }
  }

  &__actions {
    flex-shrink: 0;

    button:not(:first-of-type) {
      margin-left: 0.5rem;
    }
  }
}

.pageHeaderSubtitle {
  display: flex;
  align-items: center;
  font-size: $font-size-base;
  font-weight: 500;
  color: $casio-dark-grey;
  margin: 0 0 0.25rem 0;

  i {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    margin-right: 0.25rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include mq-lg-min {
  .pageHeader {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 2rem 0;

    &.secondary {
      padding: 0 0 1rem 0;
    }

    &__heading {
      margin-bottom: 0;
    }
  }
}
