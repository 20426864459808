@import "../../assets/variables";
@import "../../assets/mixins";

.formField {
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
  @include transitions();

  > label {
    cursor: text;
    pointer-events: none;
    display: block;
    font-size: $font-size-xs;
    font-weight: 500;
    color: $casio-dark-grey;
    width: 100%;
    z-index: 1;
    @include transitions();
  }

  label[class="disable"] {
    // color: #a0a0a0;
    color: #bababa;
  }

  &:focus-within {
    > label {
      color: $casio-blue-3;
    }
  }

  &__message {
    top: 100%;
    left: 0;
    font-size: $font-size-xs;
    position: absolute;
    padding-top: 0.125rem;
    @include transitions();

    &_error {
      opacity: 0;
      visibility: hidden;
      color: $classpad-red;
      @include transitions();
    }
    &_hint {
      opacity: 1;
      visibility: visible;
      color: $casio-dark-grey;
      @include transitions();
    }
  }
}

.formField_invalid {
  select,
  input {
    background-color: rgba($color: $classpad-red, $alpha: 0.08);
    color: $classpad-red;
  }
  textarea {
    color: $classpad-red;
  }

  .formField__message {
    &_error {
      opacity: 1;
      visibility: visible;
    }

    &_hint {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.formField_custom {
  > label {
    margin-bottom: 0.25rem;
  }
}

.formField_input {
  display: flex;
  align-items: center;

  input[id="lms_nickname_modal"] {
    font-size: 11px;
  }

  input[class="disable"],
  select[class="disable"] {
    background-color: $casio-lighter-grey;
    color: #a0a0a0;
    padding-top: ($font-size-s + 0.5rem + 0.25rem);
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    appearance: none;
    display: block;
    width: 100%;
    font-size: $font-size-s;
    border-radius: $border-radius-base;
    font-weight: 500;
    line-height: 1.85;
    border: 1px solid transparent;
    @include transitions();
    pointer-events: none !important;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  label[for="guardian_firstname"] {
    font-size: 11px;
  }

  label[for="guardian_lastname"] {
    font-size: 11px;
  }

  label[for="guardian_email"] {
    font-size: 11px;
  }

  label[for="confirm_guardian_email"] {
    font-size: 11px;
  }

  select,
  textarea,
  input {
    background-color: $casio-light-grey;
    padding-top: ($font-size-s + 0.5rem + 0.25rem);
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    appearance: none;
    display: block;
    width: 100%;
    font-size: $font-size-s;
    border-radius: $border-radius-base;
    font-weight: 500;
    line-height: 1.85;
    border: 1px solid transparent;
    @include transitions();

    &:focus {
      color: $casio-blue-2;
      background-color: $casio-blue-5;
      border-color: $casio-blue-3 !important;
      outline: none;

      + label {
        display: none;
      }
    }

    &:hover {
      background-color: $casio-blue-5;
    }
  }
}
