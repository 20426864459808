.container {
  width: 95%;
  margin: auto;
  &__text {
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__note {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    > p {
      font-size: 16px;
      margin-bottom: 10px;
    }
    &__textArea {
      height: 150px !important;
    }
  }

  &__groupBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__uploadedFile {
    height: 150px !important;
    background-color: white;
    border: 1px solid #eeeeee;
    padding: 0.3rem 0.5rem 0.5rem 0.5rem;
    margin: 0;
    width: 100%;
    resize: none;
    outline: none;
    margin-bottom: 1.5rem;

    > p {
      height: 20%;
      font-weight: bold;
    }
  }
}

.announcementText {
  font-size: 15px;
  width: 96%;
}
