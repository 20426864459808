@import "../../assets/variables";
@import "../../assets/mixins";

.container {
  margin-top: 50px;
  .historyContainer {
    .checkBoxGroupContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__label {
        font-size: 16px;
      }
    }
    .groupButtonRow {
      margin-top: 30px;
      .groupButton {
        display: flex;
        justify-content: flex-end;

        > Button {
          margin-left: 50px;
        }
      }
    }

    .tableContainer {
      margin-top: 50px;
    }
  }
}
