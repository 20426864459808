.container {
  width: 96%;
  margin: 0 auto;
  .noteContainer {
    width: 100%;
    .textArea {
      height: 400px;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .tableContainer {
    width: 100%;

    .tableTitle {
      display: flex;
      margin-bottom: 20px;
      font-size: 16px;

      > p {
        margin-right: 30px;
      }
    }
  }

  .btnGroup {
    .groupBtn2 {
      padding-top: 50px;

      display: flex;
      justify-content: flex-end;
      > Button {
        margin-right: 20px;
      }
    }
  }
}

.confirmModalText {
  font-size: 15px;
  width: 96%;
}

.confirmModalBtnGroup {
  margin-top: 50px;
  width: 96%;
  .groupBtn1 {
    justify-content: start;
  }
  .groupBtn2 {
    display: flex;
    justify-content: flex-end;
  }
}

label[for="note"] {
  color: #a0a0a0 !important;
}
