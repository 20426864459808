@import "../../assets/variables";
@import "../../assets/mixins";
@import "../../components/TeachingLevelSelect/TeachingLevelSelect.module.scss";

.info {
  margin-bottom: 0.5rem;
}

.verification_subline {
  color: $casio-dark-grey;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.email_verification {
  border-left: 0.25rem solid;
  border-color: $casio-light-grey;
  padding-left: 0.75rem;
  margin-bottom: 0.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    color: $casio-dark-grey;
    margin-bottom: 0.25rem;
    &__close {
      width: 2rem;
      height: 2rem;
      color: $casio-dark-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transitions();

      &:hover {
        color: $casio-black;
      }
    }
  }

  &__info {
    color: $casio-dark-grey;
    font-size: $font-size-s;
  }
}

.teachingSubjectSelect {
  &__control {
    font-size: $font-size-s;
    background-color: $casio-light-grey;
    border-radius: $border-radius-base;
    font-weight: 500;
    line-height: 1.85;
    border: none;
    padding: ($font-size-s + 0.4rem) 0.5rem 0.5rem 0.5rem;
    @include transitions();

    &--is-focused {
      background-color: $casio-blue-5;
    }
    &--is-focused .teachingLevel__reactSelect__single-value {
      color: $casio-blue-2;
    }
    &--is-focused .teachingLevel__reactSelect__placeholder {
      color: $casio-blue-3;
    }
    &--is-disabled {
      z-index: 1;
      opacity: 0.5;
    }
    &:hover {
      background-color: $casio-blue-5;
    }
  }

  &__value-container {
    padding: 0;
  }

  &__indicator-separator {
    margin: 0 0.25rem;
  }

  &__indicator {
    padding: 0;
  }

  &__placeholder {
    font-size: $font-size-s;
  }

  &__input {
    font-weight: 800;
  }

  &__menu {
    z-index: 2;
    font-size: $font-size-s;
  }

  &__multi-value {
    background-color: $casio-blue-2;
    color: $casio-white;
    display: flex;
    justify-content: space-between;

    &__label {
      color: $casio-white;
    }

    &__remove {
      @include transitions();
    }
  }
}
