@import "../../assets/variables";
@import "../../assets/mixins";

$sidebar-width: 50px;
$sidebar-width-lg: 300px;
$icon-size: 24px;

.sidebarLayout {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-self: stretch;

  &__sidebar {
    position: relative;
    width: $sidebar-width;
    overflow: hidden;
    height: 100%;
    flex-shrink: 0;
    background-color: $casio-blue-5;
    display: flex;
    justify-content: flex-end;
    @include transitions();

    &__toggle {
      width: $sidebar-width;
      height: $sidebar-width;
      color: $casio-white;
      position: absolute;
      top: 0.25rem;
      right: 0;
      color: $casio-blue-2;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.35rem;

      svg {
        @include transitions();
      }
    }

    &__container {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3rem 0;
      width: $sidebar-width-lg;
      height: 100%;
      @include transitions();
    }
  }

  &__content {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    width: calc(100% - #{$sidebar-width});
    padding: 1rem;
    overflow: auto;
    @include transitions;
    @include box-shadow;
  }
}

.sidebarTab {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: $casio-blue-2;
  font-weight: 700;
  padding: 0.5rem 0;
  line-height: $line-height-heading;
  position: relative;
  z-index: 10;
  @include transitions();
  white-space: nowrap;

  &:hover {
    background-color: $casio-blue-4;
    cursor: pointer;
  }

  &_active {
    &,
    &:hover {
      @include box-shadow;
      background-color: $casio-white-dimmed;
    }
  }

  i {
    width: $sidebar-width;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transitions();

    svg {
      stroke-width: 3;
      width: $icon-size;
      height: $icon-size;
    }
  }
  &__badge {
    position: absolute;
    top: 0;

    &__blue {
      top: 0.25rem;
      padding: 0.075rem 0.25rem;
      color: $casio-blue-2;
      background-color: $casio-blue-4;

      i {
        svg {
          stroke-width: 2;
        }
      }
    }
  }
}

.sidebarToggled {
  width: $sidebar-width-lg;

  .sidebarLayout__sidebar__container {
    width: 100%;
  }

  .sidebarLayout__sidebar__toggle {
    svg {
      transform: rotate(180deg);
    }
  }

  .sidebarTab {
    i {
      margin-left: ($sidebar-width - $icon-size) * 0.5;
    }
  }
}

.sidebarLayout_matheMarathon {
  .sidebarLayout__content {
    background-color: $mm-background;
    padding: 0;
    @include transitions();
  }

  .sidebarTab_active {
    background-color: $mm-background;
  }
}

@include mq-lg-min {
  .sidebarLayout {
    &__sidebar {
      width: $sidebar-width-lg;
      margin-left: 0;
      padding: 1rem 0;

      &__toggle {
        display: none;
      }

      &__container {
        width: 100%;
        padding: 1rem 0;
      }
    }

    &__content {
      width: calc(100% - #{$sidebar-width-lg});
      padding: 2rem;
    }
  }

  .sidebarTab,
  .sidebarToggled .sidebarTab {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.75rem 1.5rem;
    margin-bottom: 0;

    i {
      margin-left: -($sidebar-width - $icon-size) * 0.5;
    }
  }
}
