@import "../../../assets/variables";
@import "../../../assets/mixins";

.verificationLinkPageTISOnly {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    max-width: 500px;
    padding: 1rem;

    &__content {
      margin-bottom: 1rem;
      > p {
        margin-bottom: 1rem;
      }
    }

    h1 {
      font-size: $font-size-xl;
    }
  }
}
