@import "../../assets/variables";
@import "../../assets/mixins";

.registerForm {
  &__errors {
    width: 100%;
    color: white;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1.5;
    background-color: $classpad-red;
    border-radius: $border-radius-base;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
  }

  &__hidden {
    visibility: hidden;
  }

  &__privacyPolicy {
    margin-bottom: 1rem;
  }

  &__frenchTeacherPrivacyPolicy {
    margin-bottom: 1rem;
    font-size: $font-size-s;
  }

  &__termsAndConditions {
    margin: 1rem 0 1rem 0;
  }

  &__agreement {
    margin-bottom: 1rem;
    font-size: large;
  }

  &__teacherInfoService {
    margin-top: 2rem;

    &__info {
      font-size: $font-size-s;
      font-weight: 400;
    }

    &__boxes {
      margin: 1rem 0 1rem 0;
      font-weight: 500;

      & .indented {
        padding-top: 1rem;

        @include mq-sm-min {
          padding-left: 2rem;
        }
      }
    }

    &__extra {
      margin-bottom: 1rem;
    }
  }

  &__teacher_french {
    &__customLabel {
      @extend .text-style;
    }

    &__radios {
      margin-top: 15px;
    }

    &__intro {
      @extend .text-style;
      margin-bottom: 1.5rem !important;
      color: $casio-blue-2 !important;
    }

    &__email_contact_label_checkbox {
      @extend .text-style;
    }

    &__academic_email_warning_message {
      @extend .text-style;
      font-size: $font-size-xs !important;
    }

    &__teacher_role_informing_text {
      @extend .text-style;
      margin-top: 10px;
    }
  }
}

.text-style {
  font-size: $font-size-s;
  font-weight: 500;
  color: $casio-dark-grey;
  margin-bottom: 0.5rem;
}

.teacherVerificationRequestSubmitBox {
  border-left: 0.25rem solid;
  border-color: $casio-light-grey;
  padding-left: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  > p {
    font-size: $font-size-s;
    margin-bottom: 1.5rem;
    color: $casio-dark-grey;
  }

  &__textArea {
    height: 130px !important;
    margin-bottom: 10px;
  }

  &__uploadFilesSection {
    &__fileUploader {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 125px;
      align-items: center;
      justify-content: center;
      border: 2px dashed $casio-blue-2;
      background-color: $casio-light-grey;
      border-radius: 2px;
      text-align: center;
      line-height: 15px;

      > p {
        font-size: $font-size-s;
      }

      &__browse {
        font-size: $font-size-s;
        font-weight: 700;
        color: $casio-blue-2;

        &:hover {
          cursor: pointer;
          color: $casio-blue-3;
          @include transitions();
        }
      }
    }
  }

  &__listFilesSection {
    border: 1.7px solid #d1d1d1;
    border-radius: 5px;
    height: 125px;
    background-color: $casio-light-grey;
    overflow: hidden;
    line-height: 30px;

    > p {
      height: 20%;
      font-weight: bold;
      padding: 0px 0 30px 20px;
      color: $casio-blue-2;
      font-size: $font-size-s;
    }

    &__content {
      height: 80%;
      overflow-y: scroll;
      margin-left: 20px;

      &__fileRow {
        display: flex;
        flex-direction: row;
        > p:first-child {
          width: 70%;
          font-size: $font-size-s;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > p:nth-child(2) {
          padding-left: 25px;
          font-size: $font-size-s;
        }

        > p:last-child {
          padding-left: 10px;
          padding-right: 10px;
          font-size: $font-size-s;
          font-weight: bold;
          color: red;

          &:hover {
            cursor: pointer;
            color: #de2d59;
          }
        }
      }
    }

    &__content::-webkit-scrollbar-track {
      border: 1px solid $casio-light-grey;
      padding: 2px 0;
      background-color: $casio-light-grey;
    }

    &__content::-webkit-scrollbar {
      width: 10px;
    }

    &__content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d1d1d1;
    }

    &__errorMsg {
      padding: 5px 0 0 0 !important;
      color: $classpad-red !important;
      font-size: $font-size-xs;
      font-weight: 500 !important;
      opacity: 1;
      visibility: visible;
      @include transitions();
      position: absolute;
      bottom: -20;
    }
  }
}
