@import "../../assets/variables";
@import "../../assets/mixins";

$item-gap: 0.25rem;
$item-height: 35px;

.colorPicker {
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: -$item-gap;
  margin-right: -$item-gap;

  &__item {
    position: relative;
    padding: 0 $item-gap $item-gap $item-gap;
    cursor: pointer;

    &_cyan {
      background-color: $classpad-cyan;
    }
    &_blue {
      background-color: $classpad-blue;
    }
    &_purple {
      background-color: $classpad-purple;
    }
    &_palered {
      background-color: $classpad-pale-red;
    }
    &_magenta {
      background-color: $classpad-magenta;
    }
    &_orange {
      background-color: $classpad-orange;
    }
    &_yellow {
      background-color: $classpad-yellow;
    }
    &_green {
      background-color: $classpad-green;
    }
    &_brown {
      background-color: $classpad-brown;
    }

    label {
      height: $item-height;
      width: $item-height;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-xs;
      font-weight: 500;
      border: 1px solid transparent;
      border-radius: $border-radius-base;
      transform: scale(1);
      cursor: pointer;
      text-align: center;
      line-height: 1;
      @include transitions();

      .colorPicker__item__checkmark {
        width: 0.75rem;
        height: 0.75rem;
        opacity: 0;
        transform: scale(0.5);
        visibility: hidden;
        color: $casio-white;
        transition: transform $transition-duration $transition-timing,
          opacity $transition-duration $transition-timing,
          visibility $transition-duration $transition-timing;
      }
    }

    input[type="radio"] {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      z-index: 1;
      cursor: pointer;

      &:checked + label {
        transform: scale(1.25);
        &.colorPicker__item_cyan {
          background-color: $classpad-cyan-dark;
        }
        &.colorPicker__item_blue {
          background-color: $classpad-blue-dark;
        }
        &.colorPicker__item_purple {
          background-color: $classpad-purple-dark;
        }
        &.colorPicker__item_palered {
          background-color: $classpad-pale-red-dark;
        }
        &.colorPicker__item_magenta {
          background-color: $classpad-magenta-dark;
        }
        &.colorPicker__item_orange {
          background-color: $classpad-orange-dark;
        }
        &.colorPicker__item_yellow {
          background-color: $classpad-yellow-dark;
        }
        &.colorPicker__item_green {
          background-color: $classpad-green-dark;
        }
        &.colorPicker__item_brown {
          background-color: $classpad-brown-dark;
        }

        .colorPicker__item__checkmark {
          transform: scale(1);
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:hover {
      label {
        transform: scale(1.1);
      }
    }

    &:active {
      label {
        transform: scale(0.95);
      }
    }
  }
}
