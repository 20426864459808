@import "../../assets/variables";

.message_modal {
  &__container {
    font-size: $font-size-s;
  }

  &__messages {
    display: flex;
    flex-direction: column;

    min-height: 60vh;
    max-height: 60vh;
    overflow: auto;
  }

  &__no_messages {
    display: flex;
    flex: 1;
    align-self: center;
    align-items: center;
    color: $casio-dark-grey;
    font-size: $font-size-s;
    font-weight: bold;
  }

  &__message {
    border-bottom: 1px solid $casio-light-grey;
    &:hover {
      background-color: $casio-blue-5;
    }

    &:last-child {
      border-bottom: none;
    }

    &__container {
      display: flex;
      padding: 1rem 0 1rem 0;
    }

    &__side {
      flex: 0 0 3rem;
      display: flex;
      justify-content: flex-end;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    &__unread {
      background-color: $classpad-pale-orange;
    }

    &__icon {
      color: $casio-blue-1;

      .message_modal__message__unread & {
        color: $classpad-orange;
      }
    }

    &__main {
      flex: 1;
      padding-right: 2rem;
      min-width: 0; // https://css-tricks.com/flexbox-truncated-text/
    }

    &__content {
      margin-top: 0.5rem;
      white-space: pre-line;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &__active {
        white-space: pre-line;
        overflow: visible;
        text-overflow: clip;
      }
    }

    &__header {
      display: flex;
      gap: 0.5rem;

      &__sender {
        font-weight: 600;
      }
      &__when {
        font-size: $font-size-xs;
        color: $casio-dark-grey;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }

    &__end {
      flex: 0 0 3rem;
      justify-content: flex-start;
      padding-right: 0.5rem;

      &__icon {
        background-color: $casio-light-grey;
        font-weight: 900;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $casio-blue-2;

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.25em;
          height: 1.25em;
          cursor: pointer;

          svg {
            stroke-width: 2;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  &__close_button {
    margin-top: 2rem;
    display: flex;
    justify-content: right;
    padding: 0 1rem 1rem 0;
  }
}
