@import "../../assets/variables";
@import "../../assets/mixins";

.toastContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  padding: 1rem;
}

.toast {
  pointer-events: auto;
  margin-bottom: 1rem;
  display: flex;
  border-radius: $border-radius-base;
  min-width: 300px;
  max-width: 75%;
  color: $casio-white;
  overflow: hidden;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0.75rem;
  }

  &__close {
    color: $casio-white;
    padding: 0.75rem;
  }

  p {
    font-size: $font-size-s;
    color: $casio-white;
    padding: 0.75rem 0;
    flex-grow: 1;
  }
}

.toast_success {
  background-color: $classpad-green-dark;
  .toast__icon {
    color: $classpad-green;
  }
}

.toast_error {
  background-color: $classpad-orange-dark;
  .toast__icon {
    color: $classpad-orange;
  }
}

@mixin slideTransition($in) {
  @if $in == true {
    opacity: 1;
    transform: translateY(0);
  } @else {
    opacity: 0;
    transform: translateY(100%);
  }
  transition: opacity $transition-duration $transition-timing,
    transform $transition-duration $transition-timing;
}

.transition {
  &_appear,
  &_enter,
  &_exitActive {
    &.toastTransition {
      @include slideTransition($in: false);
    }
  }

  &_appearDone,
  &_enterDone,
  &_exit {
    &.toastTransition {
      @include slideTransition($in: true);
    }
  }
}
