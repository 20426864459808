.card {
  display: flex;
  flex-direction: column;

  &__closed,
  &__open {
    h2 {
      font-size: 32px !important;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 0;
      transition: all 0.5s ease;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__icon {
      color: silver;
    }
  }

  &__faq-items {
    margin-top: 2rem;
  }
}

.collapse {
  background-color: #eee;
  margin-bottom: 20px;
  padding-top: 0.5rem;
  padding-bottom: 1px;

  &__button {
    display: flex;
    justify-content: space-between;
    border: none;
    padding: 0.75rem 1rem 0rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: left;
    border-radius: 3px;

    h3 {
      margin: auto 0;
      font-weight: 600;
    }

    span {
      margin: 0 0 0 25px;
      align-self: center; /* Centers the <span> */
    }

    &:hover {
      color: #3957a8;
    }
  }

  &__items {
    margin: 15px;
    background-color: #fff;

    &__item {
      list-style-position: outside; /* Ensures bullet/number is outside the text */
      margin: 0px 0px 0px 0px;
      padding: 10px;
      padding-bottom: 10px;
      font-weight: 500;
      text-align: left; /* Aligns text to the left */

      &__circle {
        list-style-type: circle;
        margin-left: 20px;

        .circle {
          list-style-type: circle;
        }

        .disc {
          list-style-type: disc;
        }

        .lower-alpha {
          list-style-type: lower-alpha;

          .square {
            list-style-type: square;
          }
        }

        .square {
          list-style-type: square;

          .square {
            list-style-type: square;
          }
        }
      }

      &__disc {
        list-style-type: disc;

        .circle {
          list-style-type: circle;
        }

        .disc {
          list-style-type: disc;
        }

        .lower-alpha {
          list-style-type: lower-alpha;

          .square {
            list-style-type: square;
          }
        }

        .square {
          list-style-type: square;

          .square {
            list-style-type: square;
          }
        }
      }

      &__decimal {
        list-style-type: decimal;

        .circle {
          list-style-type: circle;
        }

        .disc {
          list-style-type: disc;
        }

        .lower-alpha {
          list-style-type: lower-alpha;

          .square {
            list-style-type: square;
          }
        }

        .square {
          list-style-type: square;

          .square {
            list-style-type: square;
          }
        }
      }

      &__lower-alpha {
        list-style-type: lower-alpha;

        .circle {
          list-style-type: circle;
        }

        .disc {
          list-style-type: disc;
        }

        .lower-alpha {
          list-style-type: lower-alpha;

          .square {
            list-style-type: square;
          }
        }

        .square {
          list-style-type: square;

          .square {
            list-style-type: square;
          }
        }
      }

      &__square {
        list-style-type: square;

        .circle {
          list-style-type: circle;
        }

        .disc {
          list-style-type: disc;
        }

        .lower-alpha {
          list-style-type: lower-alpha;

          .square {
            list-style-type: square;
          }
        }

        .square {
          list-style-type: square;

          .square {
            list-style-type: square;
          }
        }
      }

      &__discsubitems {
        list-style-type: disc;

        .disc {
          list-style-type: disc;
        }
        .circle {
          list-style-type: circle;
        }

        .lower-alpha {
          list-style-type: lower-alpha;

          .square {
            list-style-type: square;
          }
        }

        .square {
          list-style-type: square;

          .square {
            list-style-type: square;
          }
        }
      }
    }

    &__nonelist_item {
      padding: 0; /* Reset padding */
      padding-bottom: 20px;
    }
  }
}

.collapse .first-level {
  background-color: white;
  padding-right: 10px;
  margin-bottom: 20px;
}

.first-level .collapse__button h3 {
  font-size: 18px;
  padding: 0.5rem 0.75rem;
  font-weight: normal;
}

.first-level .collapse__button {
  padding: 0rem 0rem 0rem 0rem;
}

.first-level .collapse__items__item__lower-alpha p,
li {
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 15px;
}

.first-level .collapse__items {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
}

.collapse .first-level {
  padding-top: 20px;
}

.first-level .collapse__items__item__lower-alpha {
  margin-left: -20px;
}
