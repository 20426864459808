@import "../../assets/variables";
@import "../../assets/mixins";

.helpBox {
  width: 100%;
  border-radius: $border-radius-base;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &__background {
    background-color: currentColor;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.25;
  }

  i {
    position: relative;
    z-index: 1;
    width: $icon-size-l;
    height: $icon-size-l;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    color: $casio-black;

    h4 {
      margin-bottom: 0.25rem;
    }

    a {
      font-weight: 600;
      color: $casio-blue-2;
      @include transitions;

      &:hover {
        color: $casio-blue-1;
      }
    }
  }
}
