@import "../../assets/variables";
@import "../../assets/mixins";

.infoBox {
  font-size: 0.75rem;
  margin-bottom: 1rem;
  display: flex;
  border-radius: $border-radius-base;
  color: #758bc5;
  background: #eff2fa;
  align-items: center;
  padding: 0.5rem;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0.75rem;
  }

  p {
    font-size: $font-size-s;
  }
}

.infoBox_error {
  background-color: $classpad-red-5;
  color: $classpad-red;
  .infoBox__icon {
    color: $classpad-red;
  }
}
