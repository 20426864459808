@import "../../assets/variables";
@import "../../assets/mixins";

.licenseEntryView {
  margin-bottom: 1.5rem;
  &:last-of-type {
    margin-bottom: 0rem;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: currentColor;
    border-radius: $border-radius-base;
    padding: 0.75rem 1rem;
    font-weight: 600;
  }

  &_cyan {
    color: $classpad-cyan;
  }
  &_blue {
    color: $classpad-blue;
  }
  &_purple {
    color: $classpad-purple;
  }
  &_palered {
    color: $classpad-pale-red;
  }
  &_magenta {
    color: $classpad-magenta;
  }
  &_orange {
    color: $classpad-orange;
  }
  &_yellow {
    color: $classpad-yellow;
  }
  &_green {
    color: $classpad-green;
  }
  &_brown {
    color: $classpad-brown;
  }
  &_bluegrey {
    color: #e1e6f2;
  }
  &_mintgreen {
    color: #e8f9ef;
  }
  &_black {
    color: black;
  }

  &__name {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.25;

    &_bluegrey {
      color: $casio-blue-1;
    }
    &_mintgreen {
      color: $classpad-green-dark;
    }

    i {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
    }
  }

  &__info,
  &__info__green,
  &__info__blue {
    margin-bottom: 0.5rem;
    font-size: $font-size-s;

    &__blue {
      color: $casio-blue-1;
    }
    &__green {
      color: $classpad-green-dark;
    }
  }

  &__license__info__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid $casio-light-grey;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__license__info__header {
    &_code {
      color: #000000;
      font-weight: bold;
      font-size: 17px;
      width: 306px;
    }

    &_dateuse {
      color: #000000;
      font-weight: bold;
      font-size: 17px;
      // padding-left: 20px;
      width: 155px;
      text-align: right;
    }

    &_startdate {
      color: #000000;
      font-weight: bold;
      font-size: 17px;
      // padding-left: 20px;
      width: 141px;
      text-align: right;
    }

    &_expirydate {
      color: #000000;
      font-weight: bold;
      font-size: 17px;
      // padding-left: 20px;
      width: 141px;
      text-align: right;
    }
  }

  &__license_info__content {
    &_code {
      color: #1d1565;
      font-size: 18px;
      font-weight: 400;
      width: 306px;
      text-align: left;
    }

    &_usedate {
      color: #1d1565;
      font-size: 18px;
      font-weight: 400;
      width: 155px;
      text-align: right;
    }

    &_startdate {
      color: #1d1565;
      font-size: 18px;
      font-weight: 400;
      width: 141px;
      text-align: right;
    }

    &_enddate {
      color: #1d1565;
      font-size: 18px;
      font-weight: 400;
      width: 141px;
      text-align: right;
    }
  }

  &__spaceDiv {
    height: 20px;
  }

  &__status {
    font-size: $font-size-s;
    color: $casio-blue-2;
    display: flex;
    align-items: center;

    &_bluegrey {
      color: $casio-blue-1;
    }
    &_mintgreen {
      color: $classpad-green-dark;
    }

    strong {
      font-weight: 600;
    }
  }

  &_expired {
    .licenseEntryView__container {
      background-color: $casio-light-grey;
    }
    .licenseEntryView__name {
      color: $casio-dark-grey;
    }
    .licenseEntryView__status {
      color: $classpad-red;
    }
  }

  &__children {
    position: relative;
    margin: 0.25rem 0 1.5rem 0;

    &:last-of-type {
      margin: 0.25rem 0 0 0;
    }
  }
}
