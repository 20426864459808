@import "../../assets/variables";
@import "../../assets/mixins";

$item-gap: 0.25rem;

:root {
  --item-height: 35px;
}

.small {
  --item-height: 20px;
  font-size: $font-size-s;
  line-height: 0.9;
}

.labeledCheckbox {
  position: relative;
  display: block;

  label {
    z-index: 1;
    display: flex;
    align-items: flex-start;
    &::before {
      cursor: pointer;
      content: "";
      flex-shrink: 0;
      display: inline-block;
      width: var(--item-height);
      height: var(--item-height);
      background-color: $casio-light-grey;
      border: 1px solid $casio-blue-4;
      border-radius: $border-radius-base;
      margin-right: 0.5rem;
      @include transitions();
    }
  }
  &__text {
    margin-top: 4px;
  }

  &__smallText {
    margin-top: 4px;
    font-size: $font-size-s;
  }
  &__checkmark {
    pointer-events: none;
    position: absolute;
    left: 1px;
    top: 1px;
    width: var(--item-height);
    height: var(--item-height);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: $casio-white;
    transform: scale(0.5);
    @include transitions();
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    width: var(--item-height);
    height: var(--item-height);
    left: 0;
    top: 0;
    opacity: 0;

    &:checked {
      & + label::before {
        background-color: $casio-blue-2;
      }
      & + label > .labeledCheckbox__checkmark {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:hover {
    label::before {
      background-color: $casio-blue-5;
    }
  }

  &:focus-within {
    label::before {
      border-color: $casio-blue-2;
      background-color: $casio-blue-5;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
