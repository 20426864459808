@import "../../../assets/variables";
@import "../../../assets/mixins";

.verificationFeedbackPage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    max-width: 500px;
    padding: 1rem;

    &__content {
      margin-bottom: 1rem;
    }

    &__links {
      display: flex;
      flex-direction: column-reverse;

      > a {
        font-size: 0.75rem;
        color: #3957a8;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        width: 100%;
      }
      > a + a {
        margin-bottom: 1rem;
      }
      > a:hover {
        color: #092981;
      }
      &__button {
        width: 100%;
      }
    }

    h1 {
      font-size: $font-size-xl;
    }
    a {
      color: $casio-blue-2;
      @include transitions();

      &:hover {
        color: $casio-blue;
      }
    }
  }
}
