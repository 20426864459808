@import "../../assets/variables";
@import "../../assets/mixins";

.broadcast_page {
  &__container {
    display: flex;
    max-height: 70vh;
    border-bottom: 1px solid $casio-light-grey;
    gap: 1rem;
  }
  &__groups {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &__container {
      flex: 0 0 18em;
      display: flex;
      flex-direction: column;
    }
  }

  &__no_messages {
    flex: 1;
    align-self: center;
    text-align: center;
    font-size: $font-size-m;
    color: $casio-dark-grey;
    font-weight: bold;
  }

  &__messages {
    padding: 0 1rem 1rem 1rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-top: 1px solid $casio-light-grey;
    margin-left: 1rem;

    &__header {
      display: flex;
      flex-direction: column;
      padding: 0 1rem 1rem 1rem;
      font-size: $font-size-m;
      font-weight: bold;
    }

    &__container {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  &__message {
    margin: 0 0 1rem 1rem;
    font-size: $font-size-s;

    &__head {
      display: flex;
      justify-content: flex-end;
      gap: 0.25rem;
    }

    &__receiver_count {
      color: $casio-dark-grey;
    }

    &__when {
      color: $casio-dark-grey;
    }

    &__text {
      background-color: $casio-white;
      border-radius: $border-radius-base;
      white-space: pre-wrap;
      padding: 1rem;
      @include box-shadow;
    }
  }

  &__refresh {
    & i {
      cursor: pointer;

      &:hover svg {
        color: $casio-blue-3;
      }
    }
  }

  &__group_item {
    background-color: $casio-white;
    display: flex;
    max-height: 70vh;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    border-radius: $border-radius-base;
    margin-bottom: 0.5rem;
    cursor: pointer;
    @include box-shadow;

    &:hover {
      color: $casio-white;
      background-color: $casio-blue-3;
    }

    &_active {
      color: $casio-white !important;
      background-color: $casio-blue-1 !important;
    }

    &__name {
      font-weight: 700;
      flex: 1 auto;
    }
    &__when {
      font-size: $font-size-xs;
      flex: 1 auto;
      text-align: right;
    }
  }
}
