@import "../../assets/variables";
@import "../../assets/mixins";

.container {
  width: 60%;
  margin: 0 auto;

  &__sub {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;

    h1 {
      font-size: 2.5rem;
      font-weight: 900;
      line-height: 1.25;
      margin: 0;
      align-self: center;
    }

    &__btn {
      width: 100%;
    }
  }
}
