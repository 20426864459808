@import "../../assets/variables";
@import "../../assets/mixins";

* {
  box-sizing: border-box;
}

.container {
  margin-top: 50px;

  .description {
    display: flex;
    flex-direction: column;
  }

  .btn {
    float: right;
    display: flex;
    gap: 20px;
  }
}
