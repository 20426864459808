@import "../../../assets/variables";
@import "../../../assets/mixins";

.contextList {
  display: flex;
  flex-direction: column;
}

.contextListItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $casio-white;
  @include box-shadow;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem 1rem 1.5rem;
  border-radius: $border-radius-base;
  font-weight: 500;
  transform: scale(1);
  @include transitions();

  &:hover {
    background-color: $casio-blue-2;
    color: $casio-white;
    transform: scale(1.01);
  }

  &__left {
    display: flex;
    flex-direction: column;
  }

  &__external {
    display: flex;
    align-items: center;

    svg {
      height: 0.75rem;
      color: $casio-blue-3;
    }

    svg:first-of-type {
      width: 1rem;
    }
  }

  i {
    display: flex;
    align-items: center;
    width: $icon-size-base;
    height: $icon-size-base;
    color: $casio-blue-4;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__colorIndicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.5rem;
    height: 100%;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;

    &_cyan {
      background-color: $classpad-cyan;
    }
    &_blue {
      background-color: $classpad-blue;
    }
    &_purple {
      background-color: $classpad-purple;
    }
    &_palered {
      background-color: $classpad-pale-red;
    }
    &_magenta {
      background-color: $classpad-magenta;
    }
    &_orange {
      background-color: $classpad-orange;
    }
    &_yellow {
      background-color: $classpad-yellow;
    }
    &_green {
      background-color: $classpad-green;
    }
    &_brown {
      background-color: $classpad-brown;
    }
  }

  &__gar_logo {
    display: flex;

    img {
      width: 1.5rem;
    }
  }

  &__eduplaces_logo svg {
    display: flex;
    width: 2rem !important;
    height: 2rem !important;
  }
}
