@import "../../assets/variables";
@import "../../assets/mixins";

.registerRole {
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__intro {
    width: 100%;
    margin-bottom: 2rem;
  }
  &__instruction {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &__altLogins {
    margin-top: 2rem;
    &__title {
      font-weight: 700;
    }
    &__subtitle {
      font-size: $font-size-s;
      color: $casio-dark-grey;
    }
    &__links {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      margin-top: 1.5rem;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 0;
        flex-shrink: 0;
        flex-grow: 1;
        background-color: $casio-white;
        border-radius: $border-radius-base;
        padding: 1rem 2rem;
        transform: scale(1);
        @include box-shadow;
        @include transitions();

        &:hover {
          transform: scale(1.05);
        }
        svg {
          height: 32px;
          width: auto;
        }
      }
    }
  }
}

.registerRoleSelectionItem {
  width: 100%;
  border-radius: $border-radius-base;
  background-color: $casio-white;
  @include box-shadow;
  font-size: $font-size-l;
  color: $casio-blue-2;
  font-weight: 700;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  @include transitions();

  &:hover {
    background-color: $casio-blue-2;
    color: $casio-white;

    i {
      color: $casio-blue-5;
    }
  }

  span {
    text-align: left;
    line-height: 1.25;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    color: $casio-blue-4;
    svg {
      width: 100%;
      height: auto;
    }
  }
}

@include mq-md-min {
  .registerRole__altLogins__links {
    flex-direction: row;
  }
}
