@import "../../assets/variables";
@import "../../assets/mixins";

@mixin baseStyle {
  color: white;
  border-radius: $border-radius-base;
  font-size: 0.75rem;
  padding: 0.5rem;
}
.container {
  background-color: $casio-blue-2;
  @include baseStyle();
}

.container_error {
  background-color: $classpad-red;
  @include baseStyle();
}
