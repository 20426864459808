@import "../../assets/variables";
@import "../../assets/mixins";

.purchaseMethod {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 1rem;
}

.formWrapper {
  height: 100%;
  &__privacy_policy {
    a {
      color: $casio-blue-2;
      font-weight: 600;
      @include transitions();

      &:hover {
        color: $casio-blue;
      }
    }
  }
}

.footer {
  padding-top: 1rem;
  color: $casio-dark-grey;

  > p {
    margin: 0;
    font-size: $font-size-s;

    a {
      color: $casio-blue-2;
      @include transitions();

      &:hover {
        color: $casio-blue;
      }
    }
  }
}

.termsAndConditions {
  font-size: $font-size-s;
  margin-top: 1rem;
  display: grid;
  grid-row-gap: 0.75rem;
}

.returnPolicyModal {
  &__text {
    margin-bottom: 1rem;
  }
}

@include mq-lg-min {
  .footer {
    > p {
      width: 75%;
      margin-left: auto;
      padding-left: 2rem;
    }
  }
}
