@import "../../assets/variables";
@import "../../assets/mixins";

.container {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__rowsPerpage {
    display: flex;
    > p {
      font-size: 12px;
      font-weight: 400;
      line-height: 19.92px;
    }

    > select {
      margin-left: 0.5rem;
      border: $casio-light-grey 2px solid;
    }
  }

  &__paginate {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10px;
    list-style-type: none;
    font-size: small;
    font-family: "Noto Sans";

    li {
      border-radius: 2px;
      border: $casio-light-grey 2px solid;
      cursor: pointer;
      margin-right: 5px;
      position: relative;
    }
    li a {
      cursor: pointer;
      padding: 0.2rem 0.5rem;
    }

    li:hover {
      background-color: $casio-blue;
      border: $casio-blue 2px solid;
      border-color: transparent;
      color: white;
    }

    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }

    &__active {
      background-color: $casio-blue;
      color: white;
      border: $casio-blue 2px solid;
      border-color: transparent;
    }
  }
}
