@import "../../assets/variables";
@import "../../assets/mixins";

.actionCard {
  background-color: $casio-lighter-grey;
  border-radius: $border-radius-base;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @include transitions;

  > i {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $casio-white;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    color: $casio-blue-2;
    padding: 1rem;
    @include transitions;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    &__title {
      font-size: $font-size-base;
      font-weight: 600;
      margin-bottom: 0.5rem;
      @include transitions;
    }
    &__description {
      font-size: $font-size-s;
      color: $casio-dark-grey;
      text-align: left;
      @include transitions;
    }
  }
}

.actionCard:hover {
  background-color: $casio-blue-2;

  > i {
    background-color: $casio-blue-3;
    color: $casio-white;
  }

  .actionCard__content__title,
  .actionCard__content__description {
    color: $casio-white;
  }
}
