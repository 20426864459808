@import "../../assets/variables";
@import "../../assets/mixins";

.message_menu {
  &__badge:hover {
    background-color: $casio-blue-3;
    cursor: pointer;
  }
  &__badge {
    &:before {
      opacity: 0;
      content: "";
      position: fixed;
      padding: 1em 1em;
      border-radius: 1rem;
      box-shadow: 0 0 0 500rem rgba(0, 0, 0, 0.6);
      z-index: 200;
      transition: all 0.2s;
    }

    &Active:before {
      opacity: 1;
      transition: all 0.2s;
    }
  }

  &__message_container {
    h5 {
      margin: 0.75rem;
      font-size: medium;
    }
  }

  &__message_count {
    display: inline-block;
    position: absolute;
    z-index: 250;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 1rem;
    padding: 0;
    border-radius: 50%;
    background-color: $classpad-orange-dark;
    color: $casio-white;
    font-size: $font-size-xxs;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
  }

  &__message {
    border-top: 1px solid $casio-light-grey;
    height: 100%;
    display: flex;
    cursor: pointer;

    &__unread {
      background-color: $classpad-pale-orange;
    }

    &:hover {
      background-color: $casio-blue-5;
    }

    &__main {
      flex: 1;
      margin: 0.75rem;
    }

    &__header {
      font-size: $font-size-s;
      display: flex;
      justify-content: space-between;

      .message_menu__message__unread &__sender {
        &::after {
          content: " \25CF";
          color: $classpad-orange;
          position: relative;
          top: -0.25rem;
          font-size: $font-size-s;
        }
      }

      &__sender {
        color: $casio-black;
        font-weight: 600;
      }

      &__when {
        font-size: $font-size-s;
        color: $casio-dark-grey;
      }
    }
    &__text {
      margin-top: 0.1rem;
      font-size: $font-size-s;
    }

    &:last-child {
      border-bottom: 1px solid $casio-light-grey;
    }
  }

  &__no_messages {
    color: $casio-dark-grey;
    border-top: 1px solid $casio-light-grey;
    border-bottom: 1px solid $casio-light-grey;
    font-size: $font-size-s;
    font-weight: bold;
    padding: 0.75rem;
    text-align: center;
  }

  &__show_all {
    display: flex;
    justify-content: center;

    a {
      padding: 0.75rem;
      font-size: $font-size-s;
      font-weight: bold;
      color: $casio-blue-2;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      text-decoration: none;
      justify-content: center;
      text-transform: uppercase;
    }
  }
}
