.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .message {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
  }
}
