@import "../../assets/variables";
@import "../../assets/mixins";

.datepickerField_unchanged {
  select {
    color: $casio-dark-grey;
  }
}

.customPopperClass {
  z-index: 9999 !important;
}
