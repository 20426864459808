@import "../../../assets/variables";
@import "../../../assets/mixins";

.container {
  width: 70%;
  margin: 0 auto;

  &__successMessage {
    margin-bottom: 0.65rem;
  }
}
