@import "../../assets/variables";
@import "../../assets/mixins";

.support_page {
  &__container {
    display: flex;
    max-height: 70vh;
    border-bottom: 1px solid $casio-light-grey;
    gap: 1rem;
  }

  &__refresh {
    & i {
      cursor: pointer;

      &:hover svg {
        color: $casio-blue-3;
      }
    }
  }

  &__no_receivers {
    flex: 1;
    font-size: $font-size-m;
    color: $casio-dark-grey;
    font-weight: bold;
  }

  &__no_messages {
    flex: 1;
    align-self: center;
    text-align: center;
    font-size: $font-size-m;
    color: $casio-dark-grey;
    font-weight: bold;
  }

  &__receivers {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &__container {
      flex: 0 0 18em;
      display: flex;
      flex-direction: column;
    }
  }

  &__receiver {
    display: flex;
    background-color: $casio-white;
    padding: 0.5rem;
    border-radius: $border-radius-base;
    color: $casio-black;
    margin-bottom: 0.5rem;
    cursor: pointer;
    @include box-shadow;

    &:hover {
      background-color: $casio-blue-3;
      color: $casio-white;
    }

    &__active {
      background-color: $casio-blue-2 !important;
      color: $casio-white !important;
    }
  }

  &__user_info {
    flex: 0 0 10rem;
    display: flex;
    flex-direction: column;

    &__nick {
      flex: 1;
    }

    &__id {
      flex: 1;
      font-size: $font-size-xxs;
    }
  }

  &__messages_info {
    flex: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;

    &__when {
      font-size: $font-size-xs;
    }

    &__icon {
      background-color: $casio-light-grey;
      color: $casio-white;
      font-weight: 900;
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.support_page__messages_info__icon__read {
        background-color: $classpad-green-dark;
      }

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;

        svg {
          stroke-width: 2;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__messages {
    padding: 0 1rem 1rem 1rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-top: 1px solid $casio-light-grey;
    margin-left: 1rem;

    &::-webkit-scrollbar {
      display: none;
    }

    &__header {
      display: flex;
      flex-direction: column;
      padding: 0 1rem 1rem 1rem;

      &__user {
        display: flex;
        gap: 0.5rem;
        flex-direction: row;
        align-items: center;
      }
      &__nick {
        font-size: $font-size-m;
        font-weight: bold;
      }
      &__role {
        font-size: $font-size-xxs;
        color: $casio-blue-1;
        background-color: $casio-blue-5;
        -webkit-border-radius: $border-radius-base;
        -moz-border-radius: $border-radius-base;
        border-radius: $border-radius-base;
        padding: 0.15rem;
        font-weight: 600;
        text-transform: uppercase;
      }

      &__uuid {
        font-size: $font-size-xxs;
        color: $casio-dark-grey;
      }
    }

    &__container {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  &__message {
    margin: 0 0 1rem 1rem;
    font-size: $font-size-s;

    &__head {
      display: flex;
      justify-content: flex-end;
      gap: 0.25rem;
    }

    &__when {
      color: $casio-dark-grey;
    }

    &__read {
      background-color: $casio-light-grey;
      font-weight: 900;
      border-radius: 50%;
      width: 0.6rem;
      height: 0.6rem;
      display: inline-flex;
      align-items: center;
      align-self: center;

      i {
        svg {
          stroke-width: 2;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__text {
      background-color: $casio-white;
      border-radius: $border-radius-base;
      white-space: pre-wrap;
      padding: 1rem;
      @include box-shadow;
    }
  }
}
