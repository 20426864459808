@import "../../assets/variables";
@import "../../assets/mixins";

.purchasesList {
  width: 100%;
  border-collapse: collapse;
  th,
  div {
    padding: 0.75rem 0;
  }
  th {
    font-size: $font-size-s;
    text-align: left;
  }

  th:nth-child(4),
  th:nth-child(5) {
    text-align: right;
  }

  tbody {
    border-top: 1px solid $casio-light-grey;

    tr:first-of-type > td {
      padding-top: 0.75rem;
    }
    tr:last-of-type > td {
      padding-bottom: 0.75rem;
    }

    tr:nth-of-type(2) {
      padding-top: 0.5rem;
    }

    td:nth-child(4) > button,
    td:nth-child(5) > button {
      margin: auto 0 auto auto;
    }
    td:nth-child(4) > span,
    td:nth-child(5) > span {
      justify-content: right;
      color: $casio-dark-grey;
      font-style: italic;
    }

    td > button,
    td > span {
      display: flex;
      align-items: center;
      i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    button {
      color: $casio-blue;
      font-weight: 500;
      font-size: $font-size-base;
      cursor: pointer;
      @include transitions();
      &:hover {
        color: $casio-blue-2;
      }
    }

    td:nth-child(4) > button > i {
      color: $classpad-red;
      &:hover {
        color: $classpad-pale-red-dark;
      }
    }
  }
}

.purchasesListItem_withdrawnInfo {
  td {
    padding: 0 0 0.75rem 0;
    border: none;
    span {
      font-size: $font-size-s;
    }
  }
}

.purchasesListItem_withdrawn {
  td {
    color: $casio-dark-grey;
    font-style: italic;
  }
}
