@import "../../../assets/variables";
@import "../../../assets/mixins";

.userList {
  font-weight: 500;
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 0 0.5rem 0 0.5rem;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  &__headingRow {
    th {
      text-align: left;
      font-size: $font-size-l;
      line-height: 1.25;
      font-weight: 700;
      padding-bottom: 1rem;
      white-space: nowrap;

      &:last-child {
        width: 100%;
      }
    }
  }

  &__userRow {
    td {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      white-space: nowrap;
    }
    div {
      display: flex;
      flex-direction: column;
    }
    &__nickname {
      display: flex;
      align-items: center;
    }
    &__name {
      font-size: $font-size-s;
      color: $casio-dark-grey;
    }
  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid $casio-light-grey;
  }

  &__badge {
    font-size: $font-size-s;
    background-color: $casio-blue-5;
    color: $casio-blue-3;
    font-weight: 700;
    border-radius: 1.5rem;
    height: 1.5rem;
    padding: 0 0.75rem;
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: center;
  }
}

.kickUserDescription {
  padding-bottom: 1.5rem;
}

.userListAction {
  font-size: $font-size-s;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: $border-radius-base;
  @include transitions();

  i {
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.25rem;
  }

  &.base {
    color: $casio-blue-2;
    &:hover {
      background-color: $casio-blue-5;
    }
  }
  &.warning {
    color: $classpad-red;

    &:hover {
      background-color: $classpad-red-5;
    }
  }
}
