@import "../../assets/variables";
@import "../../assets/mixins";

.contentLayout {
  width: 100%;
  max-width: $content-max-width;
  margin: 0 auto;

  > div {
    padding: 1rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &_paddingY_large > div {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@include mq-sm-min {
  .contentLayout {
    > div {
      padding: 2rem;
    }

    &_paddingY_large > div {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
}
