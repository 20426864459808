@import "../../assets/variables";
@import "../../assets/mixins";

.container {
  &__searchFieldsContainer {
  }

  &__btnGroup {
    padding-top: 50px;
    &__groupOne {
      > Button {
        margin-right: 20px;
      }
    }

    &__groupThree {
      display: flex;
      justify-content: flex-end;
      > Button:first-child {
        margin-right: 20px;
      }
    }
  }

  &__tableContainer {
    margin-top: 50px;
  }
}

.row {
  margin-top: 20px;
}

.announcementText {
  font-size: 15px;
  width: 96%;
}
