@import "../../assets/variables";
@import "../../assets/mixins";

.navigationPage {
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: $content-max-width;
  margin: 0 auto;

  &__imageContainer {
    position: relative;
    width: 100%;
    padding-bottom: 40%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__navigation {
    ul {
      padding: 1rem 0;
      li {
        width: 100%;
        padding: 0.5rem 1rem;
        font-weight: 700;
        text-align: left;
        color: $casio-blue-3;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.navigationPageAside {
  padding: 0 1.5rem;
}

.navigationBoxGridItem {
  margin-bottom: 1rem;
}

.navigationBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
    h3 {
      font-size: $font-size-m;
      margin: 0;
    }

    i {
      margin-left: 1rem;
      margin-top: 0.1rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: $font-size-l;
      height: $font-size-l;
      color: $casio-light-grey;

      svg {
        width: 100%;
        height: 300%;
      }
    }
  }

  &__content {
    flex-grow: 1;
  }

  > i {
    margin-top: auto;
    display: flex;
    align-items: center;
    width: $font-size-l;
    height: $font-size-l;
    color: $casio-blue-2;
    @include transitions;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.navigationBox_matheMarathon {
  background-position: 75% calc(100% + 2rem), 10% calc(100% + 1rem),
    calc(100% + 3rem) center, 70% -5rem, 45% bottom, 85% 20%, 10% top;
  background-size: 13%, 15%, 15%, 20%, 20%, 10%, 15%;
  background-image: url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_A.svg),
    url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Dreieck.svg),
    url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Kreis.svg),
    url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Quadrat.svg),
    url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_B.svg),
    url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_C.svg),
    url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_D.svg);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;

  > i,
  .navigationBox__header i {
    color: $casio-black;
    width: 3rem;
  }

  &:hover {
    background-position: 75% 100%, 20% calc(100% - 1rem), 90% center, 60% 0rem,
      45% 80%, 75% 30%, 20% 30%;
  }
}

.navigationBox__cplTestPeriod {
  &__footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    &__badge {
      margin-left: 1rem;
    }
    > i {
      color: $casio-white;
    }
  }
}

.navigationBox__dtcTeacherVerification {
  &__footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    &__badge {
      margin-left: 1rem;
    }
    > i {
      color: $casio-black;
    }
  }
}

.navigationBox__default {
  &__footer {
    display: flex;
    align-content: center;
    > i {
      display: flex;
      align-self: center;
    }
    > div {
      margin-left: 1rem;
      align-self: center;
    }
  }
}

.matheMarathonContent {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__body {
    font-weight: 500;
    margin-bottom: 1rem;
    p + p {
      margin-top: 0.25rem;
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      display: flex;
      align-items: center;
      > i {
        width: $font-size-xl;
        height: $font-size-xl;
        border: 3px solid currentColor;
        border-radius: 50%;
        svg {
          width: 100%;
          height: auto;
        }
      }
      > span {
        margin-left: 1rem;
        font-weight: 700;
      }
    }

    &__logo {
      display: flex;
      align-items: center;
      font-size: $font-size-s;
      font-weight: 600;
      > span {
        margin-right: 1rem;
      }

      > svg {
        width: 6rem;
        height: auto;
      }
    }
  }
}

.navigationBox:hover {
  > i {
    color: $casio-white;
  }
}

@include mq-md-min {
  .navigationBox {
    padding: 1.5rem;
  }
}

@include mq-xl-min {
  .navigationPage {
    padding: 0 0 2rem 0;
  }
}
