@import "../../assets/variables";
@import "../../assets/mixins";

.selectField {
  &__indicator {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 22px;
    height: 22px;
    color: $casio-black;
    pointer-events: none;
    @include transitions();
  }
}

.selectField_unchanged {
  select {
    color: $casio-dark-grey;
  }
}
