@import "../../assets/variables";
@import "../../assets/mixins";

.button {
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: $border-radius-base;
  @include transitions();

  i {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  i + span {
    margin-left: 0.25rem;
  }

  &.default {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }

  &.small {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }

  &.extraLarge {
    width: 100%;
    font-size: $font-size-base;
    height: 7rem;

    i {
      margin-right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.fullWidth {
    width: 100%;
    height: 2.5rem;
    font-size: $font-size-base;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.4;
  }

  &.primary {
    background-color: $casio-blue-2;
    color: $casio-white;

    &:hover {
      background-color: $casio-blue-1;
    }
  }

  &.secondary {
    background-color: $casio-blue-5;
    color: $casio-blue-2;

    &:hover {
      background-color: $casio-blue-4;
    }
  }

  &.warning {
    background-color: $classpad-red-5;
    color: $classpad-red;

    &:hover {
      background-color: $classpad-red-4;
    }
  }

  &.danger {
    background-color: $classpad-red;
    color: $casio-white;

    &:hover {
      background-color: #a60a31;
    }
  }

  &.icon,
  &.icon.default {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: flex;
      align-items: center;
      padding: 0.5rem;
    }
    span {
      display: none;
    }
  }
  &.icon.small {
    i {
      padding: 0.25rem;
    }
  }
}
