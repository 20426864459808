@import "../../assets/variables";
@import "../../assets/mixins";

$item-gap: 0.25rem;
$item-height: 35px;

.checkboxes {
  margin-bottom: 1.5rem - $item-gap;

  &__label {
    font-size: $font-size-xs;
    font-weight: 500;
    color: $casio-dark-grey;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$item-gap;
    margin-right: -$item-gap;

    &__item {
      position: relative;
      padding: 0 $item-gap 2 * $item-gap $item-gap;
      cursor: pointer;
      width: 25%;

      label {
        height: $item-height;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-xs;
        font-weight: 500;
        background-color: $casio-light-grey;
        color: $casio-dark-grey;
        border: 1px solid transparent;
        border-radius: $border-radius-base;
        transform: scale(1);
        cursor: pointer;
        text-align: center;
        line-height: 1;
        @include transitions();

        .checkboxes__container__item__checkmark {
          width: 0.75rem;
          height: 0.75rem;
          margin-right: 0.25rem;
          opacity: 0;
          transform: translateX(-0.5rem) scale(0.5);
          visibility: hidden;
          transition: transform $transition-duration $transition-timing,
            opacity $transition-duration $transition-timing,
            visibility $transition-duration $transition-timing;
        }
        span {
          transition: transform $transition-duration $transition-timing;
          transform: translateX(-0.5rem);
        }
      }

      input[type="checkbox"] {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + label {
          background-color: $casio-blue-2;
          color: $casio-white;

          .checkboxes__container__item__checkmark {
            transform: translateX(0) scale(1);
            opacity: 1;
            visibility: visible;
          }

          span {
            transform: translateX(0);
          }
        }
      }

      &:hover {
        label {
          background-color: $casio-blue-5;
          color: $casio-blue-2;
        }
      }

      &:active {
        label {
          transform: scale(0.95);
        }
      }

      &:focus-within {
        label {
          border-color: $casio-blue-2;
          background-color: $casio-blue-5;
        }
      }
    }
  }
}

@include mq-lg-min {
  .checkboxes__container {
    &__item {
      width: calc(100% / 3);
    }
  }
}
