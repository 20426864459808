@import "../../assets/variables";
@import "../../assets/mixins";

.cardTopicOverview {
  position: relative;
  height: 80%;
  cursor: pointer;
}

.anchor-style {
  color: #0c41a7;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px; /* 125% */
  text-decoration-line: underline;
  list-style-type: disc;
}

@mixin common-style($color: #ffd889) {
  display: flex;
  margin-left: 0.5rem;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: auto;
    width: 0.5rem;
    height: 100%;
    border-radius: 0.25rem;
    background: $color;
  }

  &__cardTitleImage {
    width: 86px; // -> 11%
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 1rem;
  }

  &__cardTitleContentContainer {
    margin-left: 2rem;
    width: 465px; // -> 60%
    margin-bottom: 1rem;
    > h5 {
      color: #202b3b;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 22px;
      margin-top: 0;
    }
    > p {
      color: #566072;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }

  &__cardTitleArrowButtonContainer {
    width: 146px; // -> 19%
    position: relative;
    margin-bottom: 1rem;
    > button {
      > svg {
        position: absolute;
        top: 35px;
        bottom: auto;
        right: 0;
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
        color: #cccccc;
      }
    }
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: $casio-light-grey;
    margin-bottom: 0.5rem;
  }
  &__divSubject {
    position: absolute;
    background-color: $color;
    height: 30px;
    width: 99%;
    left: 10px;
    padding-left: 2rem;
    color: #1a1a1a;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 93.75% */
  }

  &__animateHeightContent {
    width: 100;
    padding-left: 2rem;
    > h4 {
      width: 100%;
    }

    &__main {
      &__gridContainer {
        margin-bottom: 2rem;
        &__firstBlankRow {
          height: 2rem;
          width: 100%;
        }
        &__row {
          width: 100%;
          height: fit-content;
          list-style-type: disc;
          color: #0c41a7;
          > a {
            @extend .anchor-style;
          }

          > li > a {
            @extend .anchor-style;
          }
        }
      }
    }
  }
}

.topicOverviewContent {
  &_yellow {
    @include common-style();
  }

  &_green {
    @include common-style(#92e3d1);
  }

  &_blue {
    @include common-style(#02acec);
  }
}
