@import "../../assets/variables";
@import "../../assets/mixins";

.header {
  background-color: $casio-blue;
  color: $casio-white;
  height: $header-height;
  width: 100%;
  overflow: visible;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 15;

  &__brand {
    display: flex;
    align-items: center;

    &__casio {
      height: 20px;
      width: auto;
      margin-right: 1rem;
    }

    &__classpad {
      height: 35px;
      width: auto;
      display: none;
    }
  }

  &__menus {
    > * {
      margin-left: 0.5rem;
    }
  }

  &__navigation {
    ul {
      list-style: none;
      display: flex;
      align-items: center;

      li {
        display: inline-flex;
      }
    }
  }

  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 6px;
    position: absolute;
    top: 100%;
    left: 0;

    @include saw-background($casio-blue);
  }
}

.navLink {
  display: flex;
  align-items: center;
  font-size: $font-size-s;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius-base;
  cursor: pointer;

  @include transitions();

  &:hover {
    background-color: $casio-blue-2;
  }

  > span {
    display: none;
  }

  i {
    display: flex;
    align-items: center;
    color: $casio-white;

    svg {
      width: 20px;
      height: auto;
    }
  }

  i + span {
    margin-left: 0.25rem;
  }
}

@include mq-md-min {
  .navLink {
    span {
      display: inline-block;
    }
  }
}

@include mq-sm-min {
  .header__brand__classpad {
    display: block;
  }
}
