.mergeSchoolsStep1Container {
  &__btnGroup {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: flex-end;
    > Button {
      margin-left: 20px;
    }
  }
}
