.text {
  > p {
    font-size: 15px;
  }
}
.btnGroup {
  width: 96%;
  margin-top: 50px;

  &__groupBtn2 {
    display: flex;
    justify-content: flex-end;
  }
}
