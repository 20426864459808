@import "../../assets/variables";
@import "../../assets/mixins";

$gutter-gap: 0.5rem;

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-gap;
  margin-right: -$gutter-gap;

  &__item {
    width: 100%;
    padding: 0 $gutter-gap;
    flex-grow: 0;
    &_1\/1 {
      width: 100%;
    }
    &__hidden {
      display: none;
    }
  }
}

@include mq-md-min {
  .grid {
    flex-direction: row;

    &__item {
      &_1\/2 {
        width: 50%;
      }
    }
  }
}

@include mq-lg-min {
  .grid {
    flex-direction: row;

    &_flipDirection {
      flex-direction: row-reverse;
    }

    &__item {
      flex-grow: 0;

      &_1\/2 {
        width: 50%;
      }
      &_1\/4 {
        width: 25%;
      }
      &_1\/5 {
        width: 20%;
      }
      &_2\/5 {
        width: 40%;
      }
      &_3\/5 {
        width: 60%;
      }
      &_3\/4 {
        width: 75%;
      }
    }
  }
}

@include mq-xl-min {
  .grid {
    &__item {
      &_1\/3 {
        width: 33.33%;
      }
      &_2\/3 {
        width: 66.66%;
      }
    }
  }
}
