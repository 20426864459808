@import "../../../assets/variables";
@import "../../../assets/mixins";

.registerForm {
  &__privacyPolicy {
    margin-bottom: 1rem;
  }

  &__termsAndConditions {
    margin: 1rem 0 1rem 0;
  }

  &__tisInfo {
    margin-top: 1rem;
    font-size: $font-size-s;
  }

  &__boxes {
    margin: 1rem 0 1rem 0;
    font-weight: 500;
    & .indented {
      padding-top: 1rem;
      @include mq-sm-min {
        padding-left: 2rem;
      }
    }
  }

  &__submitButton {
    margin-top: 1rem;
  }
}
