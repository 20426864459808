@import "../../assets/variables";
@import "../../assets/mixins";

.title {
  font-size: 14px;
  margin: auto;
  // margin-left: 50px;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.content {
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;

  > p {
    font-size: 16px;
    margin-bottom: 1.5rem;
  }

  &__textArea {
    height: 150px !important;
    margin-bottom: 10px;
  }

  &__uploadFilesSection {
    &__fileUploader {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 200px;
      align-items: center;
      justify-content: center;
      border: 2px dashed $casio-blue-2;
      border-radius: 2px;
      text-align: center;

      &__browse {
        font-weight: 700;
        color: $casio-blue-2;

        &:hover {
          cursor: pointer;
          color: $casio-blue-3;
          @include transitions();
        }
      }
    }
  }

  &__listFilesSection {
    border: 1px solid $casio-light-grey;
    height: 200px;

    > p {
      height: 20%;
      font-weight: bold;
      padding-top: 10px;
      padding-left: 20px;
    }

    &__content {
      height: 80%;
      overflow-y: scroll;
      margin-left: 20px;

      &__fileRow {
        display: flex;
        flex-direction: row;
        > p:first-child {
          width: 70%;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > p:nth-child(2) {
          padding-left: 25px;
          font-size: 14px;
        }

        > p:last-child {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 14px;
          font-weight: bold;
          color: red;

          &:hover {
            cursor: pointer;
            color: #de2d59;
          }
        }
      }
    }

    &__content::-webkit-scrollbar-track {
      padding: 2px 0;
      background-color: white;
    }

    &__content::-webkit-scrollbar {
      width: 10px;
    }

    &__content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d1d1d1;
    }

    &__errorMsg {
      padding: 5px 0 0 0 !important;
      color: $classpad-red;
      font-size: $font-size-xs;
      font-weight: 500 !important;
      opacity: 1;
      visibility: visible;
      @include transitions();
    }
  }

  &__buttonSubmit {
    margin-top: 50px;
    width: 100%;
  }
}
