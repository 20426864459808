@import "../../../assets/variables";
@import "../../../assets/mixins";

.userDetailContainer {
  width: 97%;
  .tableContainer {
    margin-top: 10px;
  }
  .btnGroup {
    .groupBtn1 {
      padding-top: 50px;

      > Button {
        margin-right: 20px;
      }
    }

    .groupBtn2 {
      padding-top: 50px;
      display: flex;
      justify-content: flex-end;
      > Button {
        margin-left: 20px;
      }
    }
  }

  .schoolSelectContainer {
    @media screen and (max-width: $break-point-sm) {
      margin-left: 0px;
    }

    @media screen and (max-width: $break-point-md) {
      margin-left: 1px;
    }

    @media screen and (max-width: 1920px) {
      margin-left: 0.8px;
    }

    @media screen and (max-width: $break-point-xxl) {
      margin-left: 0.8px;
    }

    @media screen and (max-width: $break-point-xl) {
      margin-left: 0.8px;
    }
  }
}

.licenseDetailContainer {
  width: 97%;
}

.verifyLogalGuarndianConfirmText {
  font-size: 15px;
}
