@import "../../assets/variables";
@import "../../assets/mixins";

.licenseEntryHead {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0.5rem;
    height: 100%;
    border-radius: 0.25rem;
  }

  &__blue::before {
    background: $casio-blue-1;
  }
  &__green::before {
    background: $classpad-green-dark;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      margin: 0;
      color: $casio-black;
    }
    p {
      font-size: $font-size-s;
      color: $casio-dark-grey;
    }
  }

  &__buttonIcon {
    @include transitions();
    &_flipped {
      transform: rotate(180deg);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: $casio-blue-2;
    font-size: $font-size-s;

    svg {
      stroke-width: 1;
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      cursor: pointer;
      font-weight: bold;
      svg {
        stroke-width: 2;
      }
    }
  }
}
