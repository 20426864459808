@import "../../assets/variables";
@import "../../assets/mixins";

.newsBoxIFrame {
  border-radius: $border-radius-base;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;

  iframe {
    border: 0;
    width: 100%;
    @include transitions();
  }

  &__more {
    flex-shrink: 0;
    color: $casio-blue;
    font-weight: 700;
    @include transitions();
    &:hover {
      color: $casio-blue-2;
    }
    display: flex;
    align-items: center;
    margin-top: 1rem;

    i {
      margin-left: 0.25rem;
      display: flex;
      align-items: center;
    }
  }
}
