@import "../../../assets/variables";
@import "../../../assets/mixins";

.container {
  .description {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > p {
      font-size: small;
    }

    > p:last-child {
      margin-bottom: 25px;
    }
  }

  .descriptionBottom {
    display: flex;
    flex-direction: column;

    > p {
      font-size: small;
    }

    > p:last-child {
      margin-bottom: 25px;
    }
  }

  .button {
    display: flex;
    justify-content: space-between;
  }

  .descriptionSuccessScreen {
    display: flex;
    flex-direction: column;

    > p {
      font-size: small;
    }

    > p:last-child {
      margin-bottom: 25px;
    }
  }
}
