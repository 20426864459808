@import "../../assets/variables";
@import "../../assets/mixins";

@mixin multiSelectStyles {
  .multiSelect__reactSelect {
    &__control {
      font-size: $font-size-s;
      background-color: $casio-light-grey;
      border-radius: $border-radius-base;
      font-weight: 500;
      line-height: 1.5;
      border: none;
      padding: ($font-size-s + 0.5rem + 0.25rem) 0.5rem 0.5rem 0.5rem;
      @include transitions();

      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }

      &:focus {
        color: $casio-blue-2;
        background-color: $casio-blue-5;
      }

      &:hover {
        background-color: $casio-blue-5;
      }
    }

    &__value-container {
      padding: 0;
    }

    &__indicator-separator {
      margin: 0 0.25rem;
    }

    &__indicator {
      padding: 0;
    }

    &__placeholder {
      font-size: $font-size-s;
    }

    &__input {
      font-weight: 800;
    }

    &__multi-value {
      background-color: $casio-blue-2;
      color: $casio-white;

      &__label {
        padding: 5px;
        color: $casio-white;
      }

      &__remove {
        @include transitions();
      }
    }

    &__menu {
      z-index: 2;
      font-size: $font-size-s;
    }
  }
}

.multiSelect {
  position: relative;
  padding-bottom: 1.5rem;
  > div:focus {
    outline: none;
  }

  label {
    cursor: text;
    display: block;
    font-size: $font-size-xs;
    font-weight: 500;
    color: $casio-dark-grey;
    width: 100%;
    padding: 0.5rem 0.5rem 0 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    @include transitions();
  }

  :global {
    @include multiSelectStyles();
  }
}
