.container {
  display: flex;
  justify-content: space-between;
  height: 100%;

  &__comparisonBoxOne {
    width: 45%;
    margin-right: 10px;
  }

  &__arrow {
    width: 5%;
    display: flex;
    margin: auto;
    justify-content: center;
  }

  &__comparisonBoxTwo {
    width: 45%;
    margin-right: 20px;
  }
}
