@import "../../assets/mixins";
@import "../../assets/variables";

.footer {
  background-color: $casio-light-grey;
  color: $casio-black;
  overflow: visible;
  position: relative;
  flex-shrink: 0;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: $break-point-sm) {
    padding: 1.5rem;
  }

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 6px;
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: scaleY(-1);

    @include saw-background($casio-light-grey);
  }

  &__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    @media screen and (max-width: $break-point-sm) {
      margin-bottom: 1.6rem;
    }
    &__logo {
      color: $casio-dark-grey;
      width: 250px;
      height: auto;
      margin-bottom: 2rem;
      @media screen and (max-width: $break-point-sm) {
        margin-bottom: 1.6rem;
      }
    }
    &__email {
      font-family: "Noto Sans,sans-serif";
      font-size: 20px;
      display: inline-block;
      margin-right: 1.5rem;

      &__text {
        font-size: 1rem;
        font-weight: 500;
        margin: 0;

        @media screen and (max-width: $break-point-sm) {
          font-size: 15px;
        }
      }
      &__anchor {
        color: $casio-blue-2;
        font-weight: 600;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        &:hover {
          color: $casio-blue;
        }
      }
    }
  }

  &__navigation {
    margin-bottom: 0.5rem;
    ul {
      display: flex;

      li {
        &:not(:last-of-type) {
          margin-right: 1rem;
        }
        font-weight: 700;
        text-transform: uppercase;
        font-size: $font-size-s;
        color: $casio-dark-grey;

        a {
          @include transitions();
          white-space: nowrap;
          &:hover {
            color: $casio-blue-2;
          }
        }

        @media screen and (max-width: $break-point-sm) {
          font-size: 11.25px;
        }
      }
    }
  }

  &__copyright {
    color: $casio-dark-grey;
    text-transform: uppercase;
    font-size: $font-size-s;
    font-weight: 500;
    opacity: 0.5;
    @media screen and (max-width: $break-point-sm) {
      font-size: 11.25px;
    }
  }
}
