@import "../../../assets/variables";
@import "../../../assets/mixins";

.container {
  width: 96%;
  margin: 0 auto;
  .noteContainer {
    width: 100%;

    .textArea {
      height: 400px;
    }
  }

  .tableContainer {
    width: 100%;
    .tableTitle {
      display: flex;
      margin-bottom: 20px;
      font-size: 16px;

      > p {
        margin-right: 30px;
      }
    }
  }

  .btnGroup {
    .groupBtn2 {
      padding-top: 50px;

      display: flex;
      justify-content: flex-end;
      > Button {
        margin-right: 20px;
      }
    }
  }

  select {
    /* styling */
    background-color: $casio-light-grey;
    border: none;
    border-radius: 3px;
    display: inline-block;
    font: inherit;
    font-size: 12px;
    line-height: 1.5em;
    padding: 1px 0px 1px 10px;
    width: 100%;
    height: 80%;
    /* reset */

    margin: 0 auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select.keepTisSelect {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 13px) calc(1em + 0px),
      calc(100% - 8px) calc(1em + 0px), calc(100% - 25.5px) 5px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

.confirmModalText {
  font-size: 15px;
  width: 96%;
}

.confirmModalBtnGroup {
  margin-top: 50px;
  width: 96%;
  .groupBtn1 {
    justify-content: start;
  }
  .groupBtn2 {
    display: flex;
    justify-content: flex-end;
  }
}
