@import "../../assets/variables";
@import "../../assets/mixins";

.codeGenerators {
  display: flex;
  flex-direction: column;
}

.externalInfo {
  background-color: $casio-light-grey;
  color: $casio-dark-grey;
  padding: 1.5rem 1rem;
  margin-bottom: 2rem;

  h2 {
    margin: 0 0 1rem 0;
    font-size: $font-size-base;
  }

  p {
    font-size: $font-size-s;
  }
}

.leaveContextDescription {
  padding-bottom: 1.5rem;
}

@include mq-sm-min() {
  .codeGenerators {
    flex-direction: row;
    align-items: center;

    div {
      margin-right: 0.5rem;
    }
  }
}

.gar_logo {
  img {
    width: 1.5rem;
  }
}

.deleteConfirmBtnGroup {
  width: 96%;
  .groupBtn1 {
    justify-content: start;
  }
  .groupBtn2 {
    display: flex;
    justify-content: flex-end;
  }
}

.deleteClassroomConfirmationText {
  font-size: 15px;
  width: 96%;
}

.confirmDeleteTextbox {
  margin-top: 20px;
}
