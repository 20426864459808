@import "../../assets/mixins";
@import "../../assets/variables";
.productBoxEntry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid $casio-light-grey;
  }

  &__radio {
    flex-grow: 1;
  }

  &__price {
    font-weight: 600;
    color: $casio-blue-2;
    flex-shrink: 0;
  }
}

.productBoxSelectionInfo {
  display: flex;
  justify-content: space-between;

  &__article {
    font-size: $font-size-s;
  }

  &__price {
    text-align: right;
    font-size: $font-size-s;
  }
}

@include mq-lg-min {
  .productBoxSelectionInfo {
    flex-direction: column;
    justify-content: flex-start;

    &__price {
      text-align: left;
      font-size: $font-size-l;
    }
  }
}
