.errorModalText {
  font-size: 15px;
  width: 96%;
}

.errorModalBtnGroup {
  width: 96%;
  margin-top: 50px;
  .groupBtn2 {
    display: flex;
    justify-content: flex-end;
  }
}
