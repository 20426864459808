@import "../../assets/mixins";
@import "../../assets/variables";

.selectionBox {
  background: $casio-lighter-grey;
  padding: 1rem;
  border-radius: $border-radius-base;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  &__badge {
    margin-right: 0.5rem;
  }

  &__runtime {
    color: $classpad-green-dark;
    font-size: $font-size-s;
  }

  &__infoLink {
    color: $casio-blue-2;
    @include transitions();

    &:hover {
      color: $casio-blue;
    }
  }

  h3 {
    font-size: $font-size-base;
    font-weight: 600;
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: column;

    > span {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;
    }
  }

  p {
    font-size: $font-size-s;
    color: $casio-dark-grey;
  }

  &__content {
    margin-top: 0.5rem;
  }
}

@include mq-md-min {
  .selectionBox {
    h3 {
      flex-direction: row;
      align-items: center;

      > span {
        margin-bottom: 0;
      }
    }

    &__badge {
      margin-left: 1rem;
    }
  }
}
