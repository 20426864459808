@import "../../assets/variables";
@import "../../assets/mixins";

.container {
  margin-top: 50px;

  .studentListContainer {
    margin-top: 50px;
    .checkBoxGroupContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__label {
        font-size: 16px;
      }
    }
    .groupButtonRow {
      margin-top: 30px;
      .groupButtonLeft {
        display: flex;
        justify-content: flex-start;
        gap: 30px;
      }

      .groupButtonRight {
        display: flex;
        justify-content: flex-end;
        gap: 30px;
      }
    }

    .tableContainer {
      margin-top: 50px;
    }
  }
}

.hyperlink {
  color: #339af0;

  &__underline {
    text-decoration: underline;
    @extend .hyperlink;
  }
}
