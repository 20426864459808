@import "../../assets/variables";
@import "../../assets/mixins";

.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  &__intro {
    width: 100%;
    margin-bottom: 2rem;
  }
  &__form {
    width: 100%;
  }
  &__subheading {
    font-size: $font-size-base;
    line-height: $line-height-heading;
    margin-bottom: 1rem;
  }
  &__info {
    margin-bottom: 0.5rem;
  }
}
