@mixin mq-sm-min {
  @media (min-width: $break-point-sm) {
    @content;
  }
}

@mixin mq-md-min {
  @media (min-width: $break-point-md) {
    @content;
  }
}

@mixin mq-lg-min {
  @media (min-width: $break-point-lg) {
    @content;
  }
}

@mixin mq-xl-min {
  @media (min-width: $break-point-xl) {
    @content;
  }
}

@mixin mq-xxl-min {
  @media (min-width: $break-point-xxl) {
    @content;
  }
}

$transition-duration: 0.3s;
$transition-timing: cubic-bezier(0.4, 0, 0.2, 1);

@mixin transitions {
  -webkit-transition: all $transition-duration $transition-timing;
  transition: all $transition-duration $transition-timing;
}

@mixin box-shadow {
  box-shadow: 0 0 20px 0 rgba($casio-black, 0.05);
}

/*
 * Helper to use saw-background with dynamic colors
 * https://stackoverflow.com/questions/55074106/css-background-url-svg-fill-color-not-working-not-base64-when-compiling-from-s
 */
@function url-encoded-color($color) {
  @return "%23" + str-slice("#{$color}", 2, -1);
}

@mixin saw-background($color) {
  background-image: url('data:image/svg+xml;utf8,<svg fill="#{url-encoded-color($color)}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="9px" height="6px" viewBox="0 0 9 6" version="1.1"><g stroke="none" stroke-width="1" fill-rule="evenodd"><polygon points="0 0 0 1 4.5 6 9 1 9 0"/></g></svg>');
  background-repeat: repeat-x;
  background-size: contain;
}
