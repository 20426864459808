@import "../../assets/variables";

.lmsBadge {
  background-color: $casio-lighter-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 2rem;

  &__link {
    color: $casio-dark-grey;
    height: 50%;
    padding-left: 0.75rem;
    padding-right: 0.25rem;
    display: flex;
    svg {
      width: auto;
      height: 100%;
    }
  }
  &__logo {
    padding-right: 0.75rem;
    display: flex;
    width: 5rem;
    flex: 1;
    svg {
      width: auto;
    }
  }
  &__gar_logo {
    display: flex;
    flex: auto;
    justify-content: center;

    img {
      height: 1.5rem;
    }
  }
}
