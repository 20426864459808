@import "../../assets/variables";
@import "../../assets/mixins";

.spinner {
  display: flex;
  align-items: center;

  > span {
    margin-left: 0.5rem;
    text-transform: uppercase;
    font-size: $font-size-s;
    font-weight: 500;
  }

  > i {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    position: relative;

    > svg {
      width: 100%;
      height: 100%;
      z-index: 1;
      > path {
        stroke-dasharray: 236;
        stroke-width: 10;
      }
    }
  }

  &_default {
    > span {
      color: $casio-blue-3;
    }
    > i > svg > path {
      animation: dashDefault 2.5s ease-in-out infinite;
    }
  }

  &_white {
    > span {
      color: $casio-white;
    }
    > i > svg > path {
      animation: dashWhite 2.5s ease-in-out infinite;
    }
  }
}
.spinnerContainer_fullContainer {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    flex-direction: column;
    > i {
      width: 3rem;
      height: 3rem;
    }
    > span {
      font-size: $font-size-s;
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}
.spinnerContainer_fullPage {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .spinnerContainer__backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
  }
  .spinner {
    flex-direction: column;
    > i {
      width: 4rem;
      height: 4rem;
    }
    > span {
      font-size: $font-size-base;
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

@keyframes dashDefault {
  0% {
    stroke-dashoffset: 236;
    color: $casio-light-grey;
  }
  50% {
    stroke-dashoffset: 0;
    color: $casio-blue-2;
  }
  90% {
    stroke-dashoffset: 236;
    color: $casio-light-grey;
  }
  100% {
    stroke-dashoffset: 236;
    color: $casio-light-grey;
  }
}

@keyframes dashWhite {
  0% {
    stroke-dashoffset: 236;
    color: $casio-white;
  }
  50% {
    stroke-dashoffset: 0;
    color: $casio-white;
  }
  90% {
    stroke-dashoffset: 236;
    color: $casio-white;
  }
  100% {
    stroke-dashoffset: 236;
    color: $casio-white;
  }
}

.transition {
  &_appear,
  &_enter {
    &.spinnerContainer {
      opacity: 0;
    }
  }

  &_appearDone,
  &_enterDone {
    &.spinnerContainer {
      opacity: 1;
      transition: opacity $transition-duration $transition-timing;
    }
  }

  &_exit {
    &.spinnerContainer {
      opacity: 1;
      transition: opacity $transition-duration $transition-timing;
    }
  }
  &_exitActive {
    &.spinnerContainer {
      opacity: 0;
      transition: opacity $transition-duration $transition-timing;
    }
  }
}
