@import "../../assets/variables";
@import "../../assets/mixins";

.labeledText {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  span {
    color: $casio-dark-grey;
    font-size: $font-size-s;
    margin-bottom: 0.25rem;
    line-height: 1;
  }

  p {
    font-size: inherit;
    font-weight: 600;
    line-height: 1.25;

    &.labeledText__empty {
      font-size: $font-size-s;
    }
  }
}

.labeledText_appearance_primary {
  p {
    color: $casio-blue-2;
  }
}
