@import "../../assets/variables";
@import "../../assets/mixins";

.codeGenerator {
  max-width: 300px;
  input {
    font-size: $font-size-base;
    font-weight: 700;
    cursor: pointer;
  }

  &__action {
    position: absolute;
    height: 100%;
    width: 72px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
