@import "../../../assets/variables";
@import "../../../assets/mixins";

.checkbox-card {
  position: relative;
  // border: 1px solid transparent;
  border-radius: $border-radius-base;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $casio-light-grey;
  color: $casio-dark-grey;
  transform: scale(1);
  @include transitions();

  .colorIndicator {
    position: absolute;
    left: -1px;
    top: 0;
    width: 0.5rem;
    height: 100%;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;

    &_cyan {
      background-color: $classpad-cyan;
    }
    &_blue {
      background-color: $classpad-blue;
    }
    &_purple {
      background-color: $classpad-purple;
    }
    &_palered {
      background-color: $classpad-pale-red;
    }
    &_magenta {
      background-color: $classpad-magenta;
    }
    &_orange {
      background-color: $classpad-orange;
    }
    &_yellow {
      background-color: $classpad-yellow;
    }
    &_green {
      background-color: $classpad-green;
    }
    &_brown {
      background-color: $classpad-brown;
    }
  }

  // Hide the checkbox visually
  .checkbox-input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &.checked {
    background-color: $casio-blue-2;
    color: $casio-white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .checkbox-card-input {
    margin-right: 12px;
    cursor: pointer;
  }

  .checkbox-card-label {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    gap: 10px;
  }
}
