@import "../../../assets/variables";
@import "../../../assets/mixins";

.contextOverview {
  &__banner {
    margin-bottom: 1rem;
  }

  &__import {
    margin-bottom: 2rem;

    &__subnotice {
      font-size: $font-size-s;
    }

    &__warning {
      background-color: $casio-light-grey;
      color: $casio-dark-grey;
      padding: 1rem;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: $font-size-s;
      margin-bottom: 1rem;
    }
  }

  &__import_info_box {
    background-color: $casio-light-grey;
    color: $casio-dark-grey;
    padding: 1.5rem 1rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    p {
      margin-bottom: 1rem;
    }

    &__left {
      flex: 10 1;
    }

    &__right {
      flex: 2 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0 0 0;

      svg {
        height: 1rem;
        color: $casio-blue-3;
      }
    }
  }

  &__gar_logo {
    img {
      width: 1.5rem;
    }
  }

  &__eduplaces_logo {
    > svg {
      height: 4rem !important;
      width: 3rem !important;
    }
  }
}
