@import-normalize;
@import "./fonts";
@import "./variables";
@import "./mixins";

/*
 * Elements
 */

* {
  box-sizing: border-box;
  font-family: $font-sans;
}

html {
  overflow: auto;
  font-weight: 400;
  line-height: 1.5;
  font-size: $font-size-root;
  color: $casio-black;
  background-color: $casio-white-dimmed;
  width: 100%;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration-skip-ink: auto;
  hyphens: auto;

  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
}

p {
  margin: 0;
}

h1 {
  font-size: $font-size-xxl;
  font-weight: 900;
  line-height: $line-height-heading;
  margin: 0 0 1rem 0;
}

h2 {
  font-size: $font-size-xl;
  line-height: $line-height-heading;
}

h3 {
  font-size: $font-size-l;
  font-weight: 700;
  line-height: $line-height-heading;
  margin: 0 0 1rem 0;
}

h4 {
  font-weight: 600;
  margin: 0 0 1rem 0;
}

p {
  font-size: 1rem;
  font-weight: 500;
}

a {
  color: inherit;
  font-weight: inherit;
  border: none;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input {
  padding: 0;
  &:focus {
    outline: none;
  }
}

button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

textarea {
  padding: 2rem 0.5rem 0.5rem 0.5rem;
  margin: 0;
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  background-color: $casio-light-grey;
  outline: none;

  &:focus {
    outline: none;
  }
}
