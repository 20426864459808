@import "../../assets/variables";
@import "../../assets/mixins";

.licenseRedeemModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.licenseRedeemSuccessModal {
  &__content {
    font-size: $font-size-s;
    & p {
      font-size: $font-size-s;
      padding-bottom: 1rem;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
    & ul {
      list-style: inside;
      padding-left: 1rem;
      padding-bottom: 1rem;
    }
  }
}
