@import "../../assets/variables";
@import "../../assets/mixins";

.matheMarathonIframe {
  display: flex;
  height: 100%;
  iframe {
    flex-grow: 1;
    border: 0;
    margin: 0;
    padding: 0;
  }
}

.matheMarathonInfo {
  padding: 1rem;
  width: 100%;
  height: auto;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: 35% -1rem, 90% 12rem, 25% 28rem, 80% 15rem, 90% bottom,
      66% 4rem, 2rem 12rem;
    background-size: 13%, 20%, 20%, 24%, 25%, 10%, 15%;
    background-image: url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_A.svg),
      url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Dreieck.svg),
      url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Kreis.svg),
      url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Quadrat.svg),
      url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_B.svg),
      url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_C.svg),
      url(https://s3.eu-central-1.amazonaws.com/static-files-2022.mathe-marathon.de/static/img/MM_Element_D.svg);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-attachment: fixed;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 2rem;

    svg {
      height: 2rem;
      width: auto;
    }

    svg {
      height: 2rem;
      width: auto;
    }

    &__countdown {
      display: flex;
      align-items: center;
      font-size: $font-size-xl;
      font-weight: 800;
      background-color: $casio-black;
      color: $casio-white;
      padding: 0.5rem 1rem;
      border-radius: $border-radius-base;
      margin: 0 1rem;

      span {
        font-weight: 600;
        font-size: $font-size-l;
      }

      &__days {
        line-height: 1;
        background-color: $casio-white;
        color: $casio-black;
        padding: 0.25rem 0.5rem;
        border-radius: $border-radius-base;
        margin: 0 0.75rem;
      }
    }
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 816px;
    margin: 0 auto;
    height: 100%;

    h2 {
      margin-bottom: 1.5rem;
      margin-top: 0;
      &:not(:first-of-type) {
        margin-top: 1.5rem;
      }
      font-size: $font-size-l;
      font-weight: 800;
    }

    h3 {
      font-size: $font-size-m;
      margin-top: 1rem;
      font-weight: 700;
    }

    p {
      font-weight: 600;
    }

    p + p {
      margin-top: 0.5rem;
    }

    &__footer {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: $font-size-s;
      line-height: 1;
      span {
        font-weight: 600;
      }

      svg {
        margin-left: 1rem;
        width: 7rem;
        height: auto;
      }
    }
  }
}

@include mq-md-min {
  .matheMarathonInfo__logos svg {
    height: 3rem;
  }
}

@include mq-lg-min {
  .matheMarathonInfo {
    padding: 2rem;
  }
}
