@import "../../../assets/variables";
@import "../../../assets/mixins";

.container {
  display: flex;
  flex-direction: column;

  &__register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    &__intro {
      width: 100%;
      margin-bottom: 2rem;
    }
    &__form {
      width: 100%;
    }
    &__subheading {
      font-size: $font-size-base;
      line-height: $line-height-heading;
      margin-bottom: 1rem;
    }
  }

  &__tisBanner {
    display: grid;
    height: 100%;
  }

  &__contentFit {
    @media only screen and (max-width: 2560px) {
      max-width: 100vw;
    }

    @media only screen and (min-width: 1441px) and (max-width: 1920px) {
      max-width: 99.2vw;
    }

    @media only screen and (min-width: 1367px) and (max-width: 1440px) {
      max-width: 98.95vw;
    }

    @media only screen and (min-width: 1300px) and (max-width: 1366px) {
      max-width: 98.9vw;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1299px) {
      max-width: 98.5vw;
    }

    @media only screen and (min-width: 600px) and (max-width: 1023px) {
      max-width: 100vw;
    }
    max-height: 100vh;
    margin: auto;
  }
}
