@import "../../assets/variables";
@import "../../assets/mixins";

.tutorial {
  display: flex;
  flex-direction: column;
  margin-left: -2rem;
  margin-right: -2rem;
}

.tutorialStep {
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  &__bubbleContainer {
    width: 50%;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &__bubble {
    flex-shrink: 0;
    width: 100%;
    padding-bottom: 100%;
    background-color: $casio-blue-2;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    @include transitions;

    &__device {
      position: absolute;
      width: 80%;
      padding-bottom: 160%;
      box-shadow: 0 0 10px 0 rgba($casio-black, 0.75);

      &_mobile {
        padding-bottom: 160%;
        background-color: #000;
        border-radius: 0.5rem;

        .tutorialStep__bubble__device__screen {
          top: 5%;
          bottom: 5%;
          left: 5%;
          right: 5%;
        }
      }

      &_desktop {
        padding-bottom: 64%;
        background-color: #e9e9e9;
        border-radius: 0.25rem;
        left: 20%;

        .tutorialStep__bubble__device__screen {
          top: 5px;
          bottom: 5px;
          left: 5px;
          right: 5px;
        }
      }

      img {
        position: absolute;
        width: 100%;
        height: auto;
        left: 0;
      }

      &_top {
        top: 25%;

        img {
          top: 0;
        }
      }

      &_center {
        top: 50%;
        transform: translateY(-50%);

        img {
          top: 0;
        }
      }

      &_bottom {
        bottom: 25%;

        img {
          bottom: 0;
        }
      }

      &__screen {
        position: absolute;
        background-color: $casio-white;
        overflow: hidden;
        border-radius: 3px;
      }
    }
  }

  &__title {
    margin-top: 1rem;
    font-weight: 600;
    color: $casio-blue-2;
    line-height: 1.25;
    text-align: left;
  }
}

@include mq-md-min {
  .tutorial {
    flex-direction: row;
  }
  .tutorialStep {
    max-width: calc(250px + 4rem);
    width: 33.33%;
    flex-direction: column;
    &__bubbleContainer {
      width: 100%;
      margin-right: 0;
      flex-shrink: 0;
    }
    &__bubble {
      &__device {
        &_mobile {
          border-radius: 1rem;
        }
        &_desktop {
          border-radius: 0.25rem;
        }
      }
    }
    &__title {
      text-align: center;
    }
  }
}
