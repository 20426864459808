@import "./../../../../assets/variables";
@import "./../../../../assets/mixins";

@mixin common {
  width: 100%;
  border: 1px solid $casio-blue-2;
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;

  &__row {
    border-bottom: solid 0.5px $casio-light-grey;
    text-align: start;
    vertical-align: text-top;

    &__title {
      font-weight: bold;
      padding-left: 0%;
      border-bottom: 1px solid $casio-blue-2;
      width: 100%;
      padding-top: 1%;
      padding-bottom: 1%;
      text-align: center;
      background: #eff2fa;
    }
    &__titleHidden {
      display: none;
    }

    &__item {
      padding-left: 1.5vw;
      margin-top: 10px;
      &__title {
        color: $casio-dark-grey;
        font-size: 0.8rem;
        font-weight: 500;
      }

      &__value {
        padding-left: 10px;
        font-size: 0.75rem;
        margin-bottom: 15px;
      }

      &__valueHidden {
        color: #fafafa;
        padding-left: 10px;
        font-size: 0.75rem;
      }
    }
  }
}
.container {
  @include common();
}

.containerDisable {
  @include common();
  background: #f7f8fa;
}
