@import "../../../assets/variables";
@import "../../../assets/mixins";

.search-box {
  display: flex;
  align-items: center;
  width: 40%;
  margin-bottom: 20px;
  padding-left: 10px;
  border: 1px solid $casio-light-grey;
  border-radius: $border-radius-base;
  gap: 10px;

  .search-icon {
    color: $casio-dark-grey;
    background: white;
  }

  .search-input {
    display: flex;
    width: 100%;
    height: 40px;
    border: none;
  }
}

.color {
  height: 100%;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .box {
    height: 55%;
    width: 100%;
    border: 1 transparent solid;
    border-radius: 3px;
  }

  .cyan {
    background-color: $classpad-cyan;
  }
  .blue {
    background-color: $classpad-blue;
  }
  .purple {
    background-color: $classpad-purple;
  }
  .palered {
    background-color: $classpad-pale-red;
  }
  .magenta {
    background-color: $classpad-magenta;
  }
  .orange {
    background-color: $classpad-orange;
  }
  .yellow {
    background-color: $classpad-yellow;
  }
  .green {
    background-color: $classpad-green;
  }
  .brown {
    background-color: $classpad-brown;
  }
}

.copyable {
  display: flex;
  align-items: center;
  gap: 30px;

  .text {
    width: 70px;
  }

  .action {
    display: flex;
    align-items: center;
    gap: 10px;

    .logo {
      margin-right: auto; // Pushes the logo to the left
      color: $casio-blue-6;
    }
  }
}

.action {
  color: $casio-blue-6;
}

.container {
  display: flex;
  flex-direction: column;

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
