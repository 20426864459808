@import "../../assets/variables";
@import "../../assets/mixins";

.licenseEntryAddon {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0.5rem;
    height: 100%;
    border-radius: 0.25rem;
    background: $casio-blue-1;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      margin-right: 1rem;
      h4 {
        margin: 0;
        color: $casio-black;
      }
      p {
        font-size: $font-size-s;
        color: $casio-dark-grey;
      }
    }
  }

  &__buttonIcon {
    @include transitions();
    &_flipped {
      transform: rotate(180deg);
    }
  }

  &__content {
    & > p {
      color: $casio-dark-grey;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  &__more {
    display: flex;
    align-items: center;
    color: $casio-blue-2;
    font-size: $font-size-s;

    svg {
      stroke-width: 1;
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      cursor: pointer;
      font-weight: bold;
      svg {
        stroke-width: 2;
      }
    }
  }
}

.licenseEntryAddonStep {
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: currentColor;
    margin-right: 1rem;
    flex-shrink: 0;

    > span {
      color: $casio-white;
      font-weight: 600;
    }
  }

  &__container {
    flex-grow: 1;
    > p {
      color: $casio-black;
      font-weight: 600;
      line-height: 2rem;
      margin-bottom: 0.5rem;
    }
  }

  &__content {
    color: $casio-black;
  }
}

.appDownloadLink {
  display: inline-flex;
  height: 2.5rem;
  width: auto;
  @include transitions();

  &:hover {
    transform: scale(1.05);
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }

  svg {
    width: auto;
    height: 100%;
  }
}

.desktopAppDownloadLink {
  display: inline-flex;
  align-items: center;
  color: $casio-white;
  background-color: #000;
  text-transform: uppercase;
  padding: 0.5rem 0.75rem;
  @include transitions();
  border-radius: 7px;
  border: 1px solid #919191;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    transform: scale(1.05);
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }

  > span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__label {
    font-size: 0.5rem;
    line-height: 1;
    font-weight: 500;
  }

  &__os {
    font-weight: 700;
    line-height: 1;
  }
}

.validUntilAdditionalLicenseText {
  color: $casio-dark-grey;
  font-size: 15px;
  line-height: 11px;
  font-weight: 500;
}
