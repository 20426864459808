@import "../../assets/variables";
@import "../../assets/mixins";

.withdrawPurchaseModal {
  h2 {
    font-size: $font-size-m;
  }
  h3 {
    font-size: $font-size-base;
  }
  p {
    font-size: $font-size-s;
    margin-bottom: 0.5rem;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0.5rem;
    &__first {
      margin-bottom: 0.75rem;
    }
  }
}

@include mq-sm-min {
  .withdrawPurchaseModal {
    &__buttons {
      flex-direction: row;
      &__first {
        margin-bottom: 0rem;
      }
    }
  }
}
