@import "../../../assets/variables";
@import "../../../assets/mixins";

.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 10px;
  border: 1px solid $casio-light-grey;
  border-radius: $border-radius-base;
  gap: 10px;

  .search-icon {
    color: $casio-dark-grey;
  }

  .search-input {
    display: flex;
    width: 100%;
    height: 40px;
    border: none;
  }
}

.class-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 400px;
  overflow-y: scroll;

  .headline {
    color: $casio-dark-grey;
    font-size: medium;
    font-weight: 500;
  }
}
