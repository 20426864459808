@import "../../assets/mixins";
@import "../../assets/variables";

.pageToolbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $casio-light-grey;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  > div {
    display: flex;
    align-items: center;
  }
}

.pageToolbarAction {
  font-size: $font-size-s;
  font-weight: 500;
  display: inline-flex;
  flex-grow: 0;
  text-align: left;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: $border-radius-base;
  @include transitions();

  span {
    display: none;
  }

  i + span {
    margin-left: 0.25rem;
  }

  i {
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &.base {
    color: $casio-blue-2;
    &:hover {
      background-color: $casio-blue-5;
    }
  }
  &.warning {
    color: $classpad-red;

    &:hover {
      background-color: $classpad-red-5;
    }
  }
  &.back {
    color: $casio-dark-grey;

    &:hover {
      background-color: $casio-light-grey;
    }
  }
}

@include mq-sm-min {
  .pageToolbar {
    margin-bottom: 2rem;
  }
  .pageToolbarAction {
    span {
      display: block;
    }
  }
}
