.rdgCell {
  background-color: white;

  :hover {
    cursor: pointer;
  }
}
.headerCell {
  background-color: #eff2fa;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #eeeeee;
  color: black;
}
.cell {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #eeeeee;
  color: black;
}
.line-height {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #eeeeee;
  color: black;
  line-height: normal;
  overflow-y: scroll;
}
