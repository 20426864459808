@import "../../assets/variables";
@import "../../assets/mixins";

.modalMenu {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 100;

  &__contentContainer {
    width: 20rem;
    background-color: $casio-white;
    background: $casio-white;
    position: absolute;
    border-radius: $border-radius-base;

    &::after {
      bottom: 100%;
      left: 90%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(213, 213, 213, 0);
      border-bottom-color: $casio-white;
      border-width: 20px;
      margin-left: -20px;
    }
  }
}
$duration: $transition-duration;
.transition {
  &_enter {
    transform: translateY(3rem);
    opacity: 0;
  }

  &_enterActive {
    opacity: 1;
    transform: translateY(0);
    transition: opacity $duration, transform $duration;
  }

  &_exit {
    opacity: 1;
  }
  &_exitActive {
    opacity: 0;
    transform: translateY(3rem);
    transition: opacity $duration, transform $duration;
  }
}
