.container {
  &__intro {
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__comparisonBox {
    display: flex;
    justify-content: space-between;
    height: 100%;

    &__comparisonBoxOne {
      width: 45%;
      margin-right: 10px;
    }

    &__comparisonBoxTwo {
      width: 45%;
      margin-right: 20px;
    }
  }
}
