@import "../../assets/mixins";
@import "../../assets/variables";

.blockContent {
  h1 {
    margin-bottom: 2rem;
    &:first-of-type {
      margin-top: 3rem;
    }
  }

  h2 {
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  h3 {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  a {
    color: $casio-blue-2;
    font-weight: 600;
    @include transitions();

    &:hover {
      color: $casio-blue;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  p + button,
  p + a > button {
    margin-top: 2rem;
  }

  ul {
    &:not(:first-child) {
      margin-top: 1rem;
    }
    margin-bottom: 1rem;
    padding-left: 21px;
    list-style-type: disc;
  }

  li {
    font-weight: 500;
  }

  ol {
    margin: 1rem 0;
    padding-left: 21px;
    list-style-type: lower-latin;
    li {
      padding-left: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  table {
    display: block;
    overflow: scroll;
    width: 100%;
    font-weight: 500;
    border-collapse: collapse;
    th {
      text-align: left;
      padding: 0.5rem;
      min-width: 300px;
      border-bottom: 1px solid $casio-dark-grey;
    }

    td {
      padding: 0.5rem;
      vertical-align: top;
      min-width: 300px;
      border-bottom: 1px solid $casio-dark-grey;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
