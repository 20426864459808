@import "../../assets/variables";
@import "../../assets/mixins";

@mixin stepBodySharedStyle {
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 1rem;
  }
}

.stepper {
  &_vertical {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__indicator {
    width: 100%;
  }

  &__container {
    display: flex;
    overflow: hidden;
  }

  &__stepBody {
    @include stepBodySharedStyle();
    background: $casio-white;
    max-height: 75vh;
  }

  &__stepBodyWithoutBackground {
    @include stepBodySharedStyle();
  }

  &__stepBodyWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    @include transitions();

    &_active {
      margin-left: 0;
    }
    &_done {
      margin-left: -100%;
      visibility: hidden;
    }
    &_pending {
      margin-left: 0;
      visibility: hidden;
    }
  }
}

@include mq-lg-min {
  .stepper_vertical {
    flex-direction: row;

    .stepper__container {
      width: 75%;
      margin-left: 1rem;
    }

    .stepper__indicator {
      width: 25%;
      margin-right: 1rem;
    }
  }
}
