@import "../../assets/variables";
@import "../../assets/mixins";

.Tabs {
  width: 100%;
  height: auto;
  min-height: 400px;
  background: #053742;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #e8f0f2;
  border-bottom: 2px solid $casio-dark-grey;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}

/* Tab Navigation */
ul.nav {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}

ul.nav li {
  width: 35%;
  padding-bottom: 0.5rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
  color: $casio-dark-grey;
  font-weight: 600;
}

ul.nav li:hover {
  background-color: $casio-light-grey;
  border-bottom: 5px solid $casio-blue-2;
}

ul.nav li.active {
  border-bottom: 5px solid $casio-blue;
}

/* Tab Content Styles */
.tabContentHidden {
  display: none;
}
