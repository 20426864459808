@import "../../assets/variables";
@import "../../assets/mixins";

.userProfileBadge {
  background-color: $casio-blue-2;
  font-weight: 900;
  border-radius: 50%;
  line-height: 1;
  width: 1.75em;
  height: 1.75em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $casio-white;
  @include transitions();

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;

    svg {
      stroke-width: 3;
      width: 100%;
      height: 100%;
    }
  }
}
