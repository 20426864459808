@import "../../assets/variables";
@import "../../assets/mixins";

.importContexts {
  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    button: {
      flex-basis: content;
    }
  }

  &__contexts {
    display: flex;
  }

  &__unimported_checkboxes {
    flex: 1 1 0;
    padding: 1rem 0;

    > div:first-of-type {
      font-weight: bold;
      margin: 0.5rem 0;
    }

    > div:not(:first-of-type) {
      margin: 0.5rem 0 0.5rem 1rem;
    }
  }

  &__imported_checkboxes {
    flex: 1 1 0;
    padding: 1rem 0;

    > div:first-of-type {
      font-weight: bold;
      margin: 0.5rem 0;
    }

    > div:not(:first-of-type) {
      margin: 0.5rem 0 0.5rem 1rem;
    }
  }
}
