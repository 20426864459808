@import "../../assets/variables";

.Main {
  overflow: visible;
  // overflow: auto;
  width: 100%;
  flex-grow: 1;
  display: flex;
  position: relative;
  background-color: $casio-white-dimmed;

  &:focus {
    outline: none;
  }
}
