@import "../../assets/variables";

.supNewMsgModal {
  &__container {
    // display: flex;
    // align-items: flex-start;
    gap: 1rem;

    textarea {
      color: $casio-dark-grey;
      font-size: small;
      -webkit-border-radius: $border-radius-base;
      -moz-border-radius: $border-radius-base;
      border-radius: $border-radius-base;

      &:focus {
        outline-style: solid;
        outline-color: $casio-blue-2;
        outline-offset: -1px;
        outline-width: 1px;
      }
    }
  }

  &__group_broadcast_box {
    flex: auto;
    display: grid;
    grid-gap: 0.25rem;
  }
  &__rcp_box {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 220px;

    textarea {
      height: 100px;
    }

    &__count {
      text-align: right;
      font-size: x-small;
      margin-top: 0.5rem;
    }

    &__button {
      width: 100%;
    }
  }

  &__rcp {
    padding: 0.15rem 0.35rem 0.15rem 0.35rem;
    margin-top: 0.25rem;
    font-size: small;
    width: 100%;
    background-color: $casio-lighter-grey;
    display: flex;
    align-content: space-between;

    i {
      filter: invert(19%) sepia(57%) saturate(5077%) hue-rotate(334deg)
        brightness(86%) contrast(104%);
      cursor: pointer;
      flex: auto;
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
    }

    &__info {
      flex: auto;
      display: flex;
      flex-flow: column;

      &__head {
        display: flex;
        align-items: center;

        &__nick {
          margin-right: 0.5rem;
          font-size: medium;
        }

        &__role {
          font-size: xx-small;
          background-color: $casio-light-grey;
          -webkit-border-radius: $border-radius-base;
          -moz-border-radius: $border-radius-base;
          border-radius: $border-radius-base;
          padding: 0.15rem;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      &__uuid {
        font-size: x-small;
      }
    }
  }

  &__msg_box {
    flex: 3 1 auto;
    display: flex;
    flex-direction: column;

    &__input {
      height: 400px;
    }

    &__button {
      display: flex;
      justify-content: right;
    }
  }
}
